/**
 * Created by osirvent on 25/04/2017.
 */
angular
    .module('annexaApp')
    .component('annexaObjectCustomFieldsGroup', {
        templateUrl: './components/common/annexa-object-custom-fields-group/annexa-object-custom-fields-group.html',
        controller:['SecFactory', '$filter', 'DialogsFactory', '$rootScope', 'Language', 'AnnexaFormlyFactory', '$scope', '$state', 'GlobalDataFactory','globalModals', 'AnnexaModalFactory', 'SecFactory', '$http', 'HelperService', 
        function(SecFactory, $filter, DialogsFactory, $rootScope, Language, AnnexaFormlyFactory, $scope, $state, GlobalDataFactory, globalModals, AnnexaModalFactory, SecFactory, $http, HelperService) {
    		var vm = this;
        	vm.languageColumn = Language.getActiveColumn();
        	vm.groupsTabs = [];
        	vm.subGroupsTabs = {};
        	vm.activeTab = 0;    
        	vm.isSubgroup = false;
        	vm.actualTab = undefined;
        	vm.closeBox = false;
        	vm.dataAux = {extra:{}};
        	var createGroups = function(cf){
				var newGroups = [];
				if(cf && cf.groups && cf.groups.length > 0){
					_.forEach(cf.groups, function(g){
						g.newGroups = createGroups(g);
						var addedGroup = $linq(newGroups).firstOrDefault(undefined, "x => x.id == "+g.relatedCustomField.id);
						if(addedGroup){
							addedGroup.groups.push(g);
						}else{
							newGroups.push({id: g.relatedCustomField.id, groups:[g], canRepeat: g.canRepeat, isGroup: (g.relatedCustomField.frontendType === 'CF_GROUP')});
						}
					});
				}
				return newGroups;
			}
        	
        	var createCopyGroups = function(cf){
        		var newGroups = [];
				if(cf){
					cf.value = "";
					if(cf.groups && cf.groups.length > 0){
						_.forEach(cf.groups, function(g){
							g.newGroups = createCopyGroups(g);
							var addedGroup = $linq(newGroups).firstOrDefault(undefined, "x => x.id == "+g.relatedCustomField.id);
							if(addedGroup){
								addedGroup.groups.push(g);
							}else{
								newGroups.push({id: g.relatedCustomField.id, groups:[g], canRepeat: g.canRepeat, isGroup: (g.relatedCustomField.frontendType === 'CF_GROUP')});
							}
						});
					}
				}
				return newGroups;
			}
			        	
        	vm.updateGroupCustomField = function(cfId, value, cf){
        		if(cfId && cf) {
        			var calculatedLinkeds = function(gr){
        				var ls = [];
        				if(gr && gr.groups){
        					var actual = $linq(gr.groups).firstOrDefault(undefined,"x => x.relatedCustomField && x.relatedCustomField.id == "+cfId);
        					if(!actual){
        						var linkedCFs = $linq(gr.groups).where("x => x.relatedCustomField && x.relatedCustomField.frontendType == 'SELECT_LINKED' && x.relatedCustomField.linkedCustomField && x.relatedCustomField.linkedCustomField.id == "+cfId).toArray();
        						_.forEach(gr.groups,function(g){
        							if(g.relatedCustomField && g.relatedCustomField.frontendType === 'CF_GROUP'){
        								var lss = calculatedLinkeds(g);
        								if(lss && lss.length > 0){
        									_.forEach(lss, function(l){
        										linkedCFs.push(l);
        									});
        								}
        							}
        						});	
        						if(linkedCFs && linkedCFs.length > 0){
									_.forEach(linkedCFs, function(l){
										ls.push(l);
									});
								}
        					}
    							
        				}
        				return ls;
        			}
        			if(vm.customField && vm.customField.groups){
        				var linkedCFs = $linq(vm.customField.groups).where("x => x.relatedCustomField && x.relatedCustomField.frontendType == 'SELECT_LINKED' && x.relatedCustomField.linkedCustomField && x.relatedCustomField.linkedCustomField.id == "+cfId).toArray();
						_.forEach(vm.customField.groups,function(g){
							if(g.relatedCustomField && g.relatedCustomField.frontendType === 'CF_GROUP'){
								var ls = calculatedLinkeds(g);
								if(ls && ls.length > 0){
									_.forEach(ls, function(l){
										linkedCFs.push(l);
									});
								}
							}
						});
						if(linkedCFs && linkedCFs.length > 0){
							_.forEach(linkedCFs, function(customField){
								if(customField && customField.relatedCustomField && customField.relatedCustomField.fromQuery && customField.relatedCustomField.query && customField.relatedCustomField.query.id){
	                        		if(vm.dataAux && vm.dataAux.extra.valuesFromLinkedCustomFields.parentId && vm.dataAux.extra.valuesFromLinkedCustomFields.inConfig != undefined && vm.dataAux.extra.valuesFromLinkedCustomFields.objectParent && customField.identifierField && customField.labelPropField && value) {
	                        			var url = "";
	                        			if(vm.dataAux.extra.valuesFromLinkedCustomFields.objectParent === "PROCEDURE"){
	                        				url = './api/procedures/calculateGroupCustomFields/'+vm.dataAux.extra.valuesFromLinkedCustomFields.parentId+'/'+vm.dataAux.extra.valuesFromLinkedCustomFields.inConfig+'/'+((customField.id == undefined)?-1:customField.id)+'/'+customField.relatedCustomField.id+'/'+value+'/'+cf.relatedCustomField.id;
	                        			}else if(vm.dataAux.extra.valuesFromLinkedCustomFields.objectParent === "TASK_TYPE"){
	                        				url = './api/task_type/calculateGroupCustomFields/'+vm.dataAux.extra.valuesFromLinkedCustomFields.parentId+'/'+vm.dataAux.extra.valuesFromLinkedCustomFields.inConfig+'/'+((customField.id == undefined)?-1:customField.id)+'/'+customField.relatedCustomField.id+'/'+value+'/'+cf.relatedCustomField.id;
	                        			}else if(vm.dataAux.extra.valuesFromLinkedCustomFields.objectParent === "DOCUMENT_TYPE"){
	                        				url = './api/doc/document_type/calculateGroupCustomFields/'+vm.dataAux.extra.valuesFromLinkedCustomFields.parentId+'/'+vm.dataAux.extra.valuesFromLinkedCustomFields.inConfig+'/'+((customField.id == undefined)?-1:customField.id)+'/'+customField.relatedCustomField.id+'/'+value+'/'+cf.relatedCustomField.id;
	                        			}
	                        			if(url && url !== ""){
	                        				$http({method: 'GET',url: url}).then(function(data){
	                        				  var newopts = [];
		                            		  if(data){
		                            			  var opts = ((data && data.data && data.data.length > 0)?JSOG.decode(data.data):[]);
		                            			  _.forEach(opts, function(lv){
		                            					if(lv[customField.identifierField]){
		                            						var label = '';
		                            						var labelPropFieldS = customField.labelPropField.split("--@--@--");
		                            						_.forEach(labelPropFieldS, function(lp){
		                            							label = label + ((label)?((customField.labelFieldsSeparator)?customField.labelFieldsSeparator:'')+" "+((lv[lp])?lv[lp]:''):((lv[lp])?lv[lp]:''));
		                            						});
		                            						newopts.push({id: lv[customField.identifierField], language1:label, language2:label, language3:label, value:lv[customField.identifierField] });
		                            					}
		                            				});
		                            		  }
		                            		  customField.relatedCustomField.listValues = newopts;
	                        				}).catch(function(error){
	                        					customField.relatedCustomField.listValues = [];
	                        				});
	                        				
	                        			 } else {
	                        				 customField.relatedCustomField.listValues = [];
	                                     }
	                                } else {
	                                	customField.relatedCustomField.listValues = [];
	                                }
	                        	}else if(cf.id && value) {
	                        		var options = [];
	                        		if(customField.relatedCustomField.listValuesAux){
	                        			options = $linq(customField.relatedCustomField.listValuesAux).where("x => x.linkedCustomFieldId == " + cf.relatedCustomField.id + " && x.parentValue.id == " + value).toArray();	                        			
	                        		}else{
	                        			customField.relatedCustomField.listValuesAux = angular.copy(customField.relatedCustomField.listValues);
	                        			options = $linq(customField.relatedCustomField.listValues).where("x => x.linkedCustomFieldId == " + cf.relatedCustomField.id + " && x.parentValue.id == " + value).toArray();
	                        		}
	                                customField.relatedCustomField.listValues = options;
	                            } else if (cf.id && !value) {
	                            	customField.relatedCustomField.listValues = [];
	                            }
							});
						}
        			}
        		}
        	}
        	vm.tabSelected = function(tab){
        		vm.groupIdSelected = ((tab)?tab.groupId+'_'+tab.isGroup:undefined);
        		vm.actualTab = tab;
        	}
        	vm.showBox = function(){
        		vm.closeBox = ((vm.closeBox)?false:true);
        	}
        	
        	vm.addGroup = function(){
        		if(vm.actualTab && vm.actualTab.cfs && vm.actualTab.cfs.length > 0 && vm.actualTab.cfs[0].group && vm.actualTab.cfs[0].group.groups && vm.actualTab.cfs[0].group.groups.length > 0){
        			var copy = angular.copy(vm.actualTab.cfs[0].group.groups[0]);
        			copy.newGroups = createCopyGroups(copy);
        			vm.actualTab.cfs[0].group.groups.push(copy)
        		}
        	}
        	vm.deletedGroup = function(group, index){
        		if(group && group.groups && group.groups.length > 1 && group.groups.length > index){
        			group.groups.splice(index,1);	
        		}
        	}
        	vm.addCF = function(group, cf){
        		if(group && group.groups && group.groups.length > 0){
        			var copy = angular.copy(group.groups[0]);
        			copy.newGroups = createCopyGroups(copy);
        			group.groups.push(copy)
        		}
        	}
        	vm.deletedCF = function(group, index){
        		if(group && group.groups && group.groups.length > 1 && group.groups.length > index){
        			group.groups.splice(index,1);	
        		}
        	}
			
			this.$onInit = function() {
				var createTabs = function(cf, isGroup){
					var tabs = [];
					var tab ={ heading: 'global.sec.literals.generalData', class: 'col-md-6 col-lg-3 col-xxl-3 tab tabPrincipal text-xs', content: '', select: undefined, deselect: undefined, groupId:cf.id, isGroup: isGroup, cfs:[], canRepeat:false, original:cf};
		        	tabs.push(tab);
					if(cf && cf.newGroups && cf.newGroups.length > 0){
		        		_.forEach(cf.newGroups, function(g){
		        			if(g.isGroup){
		        				var tabG = { heading: g.groups[0].relatedCustomField[vm.languageColumn], class: 'col-md-6 col-lg-3 col-xxl-3 tab tabPrincipal text-xs', content: '', select: undefined, deselect: undefined, groupId:g.id, isGroup: true, cfs:[], canRepeat:g.canRepeat, original:g};
		        				tabs.push(tabG);
		        				tabG.cfs.push({
		        					tabs: createTabs(g.groups[0], true),
		        					group: g,
		        					activeTab:0
		        				});
		        			}else{
		        				tab.cfs.push({group:g});
		        			}
		        		});
		        	}
		        	return tabs;
				}
				vm.customField.newGroups = createGroups(vm.customField);
				vm.groupTabs = createTabs(vm.customField, (((vm.data && vm.data.extra && vm.data.extra.subGroup))?true:false));
				if(vm.data){
					vm.dataAux = angular.copy(vm.data);
					if(vm.dataAux.extra){
						vm.isSubgroup = ((vm.dataAux && vm.dataAux.extra && vm.dataAux.extra.subGroup)?true:false);
						vm.dataAux.extra.subGroup = true;
					}
				}
				vm.activeTab = 0;
				vm.actualTab = ((vm.groupTabs && vm.groupTabs.length > vm.activeTab)?vm.groupTabs[vm.activeTab]:undefined);
				vm.groupIdSelected = vm.customField.id+'_'+vm.isSubgroup;
				vm.closeBox = false;
	       }
        }],
        bindings: {
        	customField: '=',
            data: '='
        }
    })
	.component('annexaObjectCustomFieldsEditGroup', {
        templateUrl: './components/common/annexa-object-custom-fields-group/annexa-object-custom-fields-edit-group.html',
        controller:['TramNewFactory', '$filter', 'DialogsFactory', '$rootScope', 'Language', 'AnnexaFormlyFactory', '$scope', '$state', 'GlobalDataFactory','globalModals', 'AnnexaModalFactory', 'SecFactory', '$http', 'HelperService', 'TramNewFactory', 'TaskFactory', 'DccumentsFactory', 'CustomFieldFactory', 
            function(TramNewFactory, $filter, DialogsFactory, $rootScope, Language, AnnexaFormlyFactory, $scope, $state, GlobalDataFactory, globalModals, AnnexaModalFactory, SecFactory, $http, HelperService, TramNewFactory, TaskFactory, DccumentsFactory, CustomFieldFactory) {
    		var vm = this;
        	vm.languageColumn = Language.getActiveColumn();
        	vm.groupsTabs = [];
        	vm.subGroupsTabs = {};
        	vm.activeTab = 0;    
        	vm.isSubgroup = false;
        	vm.actualTab = undefined;
        	vm.closeBox = false;
        	vm.isEdit = false;
        	vm.dataAux = {extra:{}};
        	var createGroups = function(cf){
				var newGroups = [];
				if(cf && cf.groups && cf.groups.length > 0){
					_.forEach(cf.groups, function(g){
						g.newGroups = createGroups(g);
						var addedGroup = $linq(newGroups).firstOrDefault(undefined, "x => x.id == "+g.relatedCustomField.id);
						if(addedGroup){
							addedGroup.groups.push(g);
						}else{
							newGroups.push({id: g.relatedCustomField.id, groups:[g], canRepeat: g.canRepeat, isGroup: (g.relatedCustomField.frontendType === 'CF_GROUP')});
						}
					});
				}
				return newGroups;
			}
        	
        	var createCopyGroups = function(cf){
        		var newGroups = [];
				if(cf){
					cf.value = "";
					if(cf.groups && cf.groups.length > 0){
						_.forEach(cf.groups, function(g){
							g.newGroups = createCopyGroups(g);
							var addedGroup = $linq(newGroups).firstOrDefault(undefined, "x => x.id == "+g.relatedCustomField.id);
							if(addedGroup){
								addedGroup.groups.push(g);
							}else{
								newGroups.push({id: g.relatedCustomField.id, groups:[g], canRepeat: g.canRepeat, isGroup: (g.relatedCustomField.frontendType === 'CF_GROUP')});
							}
						});
					}
				}
				return newGroups;
			}
			        	
        	vm.updateGroupCustomField = function(field){
        		if(field) {
        			if(vm.dataAux && vm.dataAux.extra && vm.dataAux.extra.valuesFromLinkedCustomFields && vm.dataAux.extra.valuesFromLinkedCustomFields.objectParent === 'DOSSIER'){
        				TramNewFactory.updateDossierCustomFieldGroup(field).then(function (data) {
        					if (field.relatedCustomField.frontendType == 'SELECT') {
        						$scope.$broadcast('customFieldSelectSelectedGroup', {
        							customField: field.relatedCustomField.id,
        							selectedValue: field.id
        						});
        					}  else if (field.relatedCustomField.backendType == 'ADDRESS') {
        						if(field.value){
        							var aux = angular.fromJson(field.value);
        							if(aux){
        								field.valueFromJSON = aux;
        							}else {
        								field.valueFromJSON = '';
        							}
        						}else{
        							field.valueFromJSON = '';
        						}
        					}
//                        if(field.relatedCustomFields && field.relatedCustomFields.length > 0){
//                        	_.forEach(field.relatedCustomFields,function(related){
//                        		var rel = $linq(vm.content).firstOrDefault(undefined, "x => x.customField && x.customField.templateTag == '"+related.customFieldTemplateTag+"'");
//                        		if(rel && rel.customField && rel.customField.templateTag){
//                        			if(field.valuesRelatedCustomFields && field.valuesRelatedCustomFields[rel.customField.templateTag]){
//                        				 rel.valueFromJSON = field.valuesRelatedCustomFields[rel.customField.templateTag];
//                        			}
//                        			if(field.jsonValuesRelatedCustomFields && field.jsonValuesRelatedCustomFields[rel.customField.templateTag]){
//                        				rel.value = field.jsonValuesRelatedCustomFields[rel.customField.templateTag];
//                        			}
//                        		}
//                        	});
//                        }
        					calculatedAndDeletedCFsAndGroups(data);
        				}).catch(function (error) {
        					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
        					console.error(error);
        				});
        			}else if(vm.dataAux && vm.dataAux.extra && vm.dataAux.extra.valuesFromLinkedCustomFields && vm.dataAux.extra.valuesFromLinkedCustomFields.objectParent === 'DOSSIER_TRANSACTION'){
        				TramNewFactory.updateDossierTransactionCustomFieldGroup(field).then(function (data) {
        					if (field.relatedCustomField.frontendType == 'SELECT') {
        						$scope.$broadcast('customFieldSelectSelectedGroup', {
        							customField: field.relatedCustomField.id,
        							selectedValue: field.id
        						});
        					}  else if (field.relatedCustomField.backendType == 'ADDRESS') {
        						if(field.value){
        							var aux = angular.fromJson(field.value);
        							if(aux){
        								field.valueFromJSON = aux;
        							}else {
        								field.valueFromJSON = '';
        							}
        						}else{
        							field.valueFromJSON = '';
        						}
        					}
//                            if(field.relatedCustomFields && field.relatedCustomFields.length > 0){
//                            	_.forEach(field.relatedCustomFields,function(related){
//                            		var rel = $linq(vm.content).firstOrDefault(undefined, "x => x.customField && x.customField.templateTag == '"+related.customFieldTemplateTag+"'");
//                            		if(rel && rel.customField && rel.customField.templateTag){
//                            			if(field.valuesRelatedCustomFields && field.valuesRelatedCustomFields[rel.customField.templateTag]){
//                            				 rel.valueFromJSON = field.valuesRelatedCustomFields[rel.customField.templateTag];
//                            			}
//                            			if(field.jsonValuesRelatedCustomFields && field.jsonValuesRelatedCustomFields[rel.customField.templateTag]){
//                            				rel.value = field.jsonValuesRelatedCustomFields[rel.customField.templateTag];
//                            			}
//                            		}
//                            	});
//                            }
        					calculatedAndDeletedCFsAndGroups(data);
        				}).catch(function (error) {
        					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
        					console.error(error);
        				});
        			}else if(vm.dataAux && vm.dataAux.extra && vm.dataAux.extra.valuesFromLinkedCustomFields && vm.dataAux.extra.valuesFromLinkedCustomFields.objectParent === 'TASK'){
        				TaskFactory.updateTaskCustomFieldGroup(field).then(function (data) {
        					if (field.relatedCustomField.frontendType == 'SELECT') {
        						$scope.$broadcast('customFieldSelectSelectedGroup', {
        							customField: field.relatedCustomField.id,
        							selectedValue: field.id
        						});
        					}  else if (field.relatedCustomField.backendType == 'ADDRESS') {
        						if(field.value){
        							var aux = angular.fromJson(field.value);
        							if(aux){
        								field.valueFromJSON = aux;
        							}else {
        								field.valueFromJSON = '';
        							}
        						}else{
        							field.valueFromJSON = '';
        						}
        					}
//                            if(field.relatedCustomFields && field.relatedCustomFields.length > 0){
//                            	_.forEach(field.relatedCustomFields,function(related){
//                            		var rel = $linq(vm.content).firstOrDefault(undefined, "x => x.customField && x.customField.templateTag == '"+related.customFieldTemplateTag+"'");
//                            		if(rel && rel.customField && rel.customField.templateTag){
//                            			if(field.valuesRelatedCustomFields && field.valuesRelatedCustomFields[rel.customField.templateTag]){
//                            				 rel.valueFromJSON = field.valuesRelatedCustomFields[rel.customField.templateTag];
//                            			}
//                            			if(field.jsonValuesRelatedCustomFields && field.jsonValuesRelatedCustomFields[rel.customField.templateTag]){
//                            				rel.value = field.jsonValuesRelatedCustomFields[rel.customField.templateTag];
//                            			}
//                            		}
//                            	});
//                            }
        					calculatedAndDeletedCFsAndGroups(data);
        				}).catch(function (error) {
        					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
        					console.error(error);
        				});
        			}else if(vm.dataAux && vm.dataAux.extra && vm.dataAux.extra.valuesFromLinkedCustomFields && vm.dataAux.extra.valuesFromLinkedCustomFields.objectParent === 'DOCUMENT'){
        				DccumentsFactory.updateDocumentCustomFieldGroup(field).then(function (data) {
        					if (field.relatedCustomField.frontendType == 'SELECT') {
        						$scope.$broadcast('customFieldSelectSelectedGroup', {
        							customField: field.relatedCustomField.id,
        							selectedValue: field.id
        						});
        					}  else if (field.relatedCustomField.backendType == 'ADDRESS') {
        						if(field.value){
        							var aux = angular.fromJson(field.value);
        							if(aux){
        								field.valueFromJSON = aux;
        							}else {
        								field.valueFromJSON = '';
        							}
        						}else{
        							field.valueFromJSON = '';
        						}
        					}
//                            if(field.relatedCustomFields && field.relatedCustomFields.length > 0){
//                            	_.forEach(field.relatedCustomFields,function(related){
//                            		var rel = $linq(vm.content).firstOrDefault(undefined, "x => x.customField && x.customField.templateTag == '"+related.customFieldTemplateTag+"'");
//                            		if(rel && rel.customField && rel.customField.templateTag){
//                            			if(field.valuesRelatedCustomFields && field.valuesRelatedCustomFields[rel.customField.templateTag]){
//                            				 rel.valueFromJSON = field.valuesRelatedCustomFields[rel.customField.templateTag];
//                            			}
//                            			if(field.jsonValuesRelatedCustomFields && field.jsonValuesRelatedCustomFields[rel.customField.templateTag]){
//                            				rel.value = field.jsonValuesRelatedCustomFields[rel.customField.templateTag];
//                            			}
//                            		}
//                            	});
//                            }
        					calculatedAndDeletedCFsAndGroups(data);
        				}).catch(function (error) {
        					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
        					console.error(error);
        				});
        			}
                }
        	}
        	vm.tabSelected = function(tab){
        		vm.groupIdSelected = ((tab)?tab.groupId+'_'+tab.isGroup:undefined);
        		vm.actualTab = tab;
        	}
        	vm.showBox = function(){
        		vm.closeBox = ((vm.closeBox)?false:true);
        	}
        	var deleteValueFromGroups = function(cf){
        		if(cf){
					cf.value = "";
					if(cf.groups && cf.groups.length > 0){
						_.forEach(cf.groups, function(g){
							deleteValueFromGroups(g);
						});
					}
				}
			}
        	vm.addGroup = function(){
        		if(vm.actualTab && vm.actualTab.cfs && vm.actualTab.cfs.length > 0 && vm.actualTab.cfs[0].group && vm.actualTab.cfs[0].group.groups && vm.actualTab.cfs[0].group.groups.length > 0){
        			var copy = angular.copy(vm.actualTab.cfs[0].group.groups[0]);
        			deleteValueFromGroups(copy);
        			if(vm.dataAux && vm.dataAux.extra && vm.dataAux.extra.valuesFromLinkedCustomFields && vm.dataAux.extra.valuesFromLinkedCustomFields.objectParent === 'DOSSIER'){
            			TramNewFactory.addDossierCustomFieldGroup(copy).then(function (data) {
	        				if(data){
		        				data.newGroups = createCopyGroups(data);
		        				vm.actualTab.cfs[0].group.groups.push(data);
		        				addToCFs(data, ((vm.actualTab.cfs[0].group.groups && vm.actualTab.cfs[0].group.groups.length > 0)?vm.actualTab.cfs[0].group.groups[0]: undefined));
	        				}else{
	        					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	        				}
	        			}).catch(function (error) {
	    					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	    					console.error(error);
	    				})	
        			}else if(vm.dataAux && vm.dataAux.extra && vm.dataAux.extra.valuesFromLinkedCustomFields && vm.dataAux.extra.valuesFromLinkedCustomFields.objectParent === 'DOSSIER_TRANSACTION'){
        				TramNewFactory.addDossierTransactionCustomFieldGroup(copy).then(function (data) {
	        				if(data){
		        				data.newGroups = createCopyGroups(data);
		        				vm.actualTab.cfs[0].group.groups.push(data);
		        				addToCFs(data, ((vm.actualTab.cfs[0].group.groups && vm.actualTab.cfs[0].group.groups.length > 0)?vm.actualTab.cfs[0].group.groups[0]: undefined));
	        				}else{
	        					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	        				}
	        			}).catch(function (error) {
	    					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	    					console.error(error);
	    				})
        			}else if(vm.dataAux && vm.dataAux.extra && vm.dataAux.extra.valuesFromLinkedCustomFields && vm.dataAux.extra.valuesFromLinkedCustomFields.objectParent === 'TASK'){
        				TaskFactory.newTaskCustomFieldGroup(copy).then(function (data) {
	        				if(data){
		        				data.newGroups = createCopyGroups(data);
		        				vm.actualTab.cfs[0].group.groups.push(data);
		        				addToCFs(data, ((vm.actualTab.cfs[0].group.groups && vm.actualTab.cfs[0].group.groups.length > 0)?vm.actualTab.cfs[0].group.groups[0]: undefined));
	        				}else{
	        					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	        				}
	        			}).catch(function (error) {
	    					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	    					console.error(error);
	    				})
        			}else if(vm.dataAux && vm.dataAux.extra && vm.dataAux.extra.valuesFromLinkedCustomFields && vm.dataAux.extra.valuesFromLinkedCustomFields.objectParent === 'DOCUMENT'){
        				DccumentsFactory.newDocumentCustomFieldGroup(copy).then(function (data) {
	        				if(data){
		        				data.newGroups = createCopyGroups(data);
		        				vm.actualTab.cfs[0].group.groups.push(data);
		        				addToCFs(data, ((vm.actualTab.cfs[0].group.groups && vm.actualTab.cfs[0].group.groups.length > 0)?vm.actualTab.cfs[0].group.groups[0]: undefined));
	        				}else{
	        					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	        				}
	        			}).catch(function (error) {
	    					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	    					console.error(error);
	    				})
        			}
        		}
        	}
        	vm.deletedGroup = function(group, index){
        		if(group && group.groups && group.groups.length > 1 && group.groups.length > index){
        			if(vm.dataAux && vm.dataAux.extra && vm.dataAux.extra.valuesFromLinkedCustomFields && vm.dataAux.extra.valuesFromLinkedCustomFields.objectParent === 'DOSSIER'){
                		TramNewFactory.deleteDossierCustomFieldGroup(group.groups[index].id).then(function (data) {
	        				if(data){
	        					var idToDelete = {id: group.groups[index].id};
	        					group.groups.splice(index,1);
	        					deleteToCFs(idToDelete);
	        				}else{
	        					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	        				}
	        			}).catch(function (error) {
	    					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	    					console.error(error);
	    				});
        			}else if(vm.dataAux && vm.dataAux.extra && vm.dataAux.extra.valuesFromLinkedCustomFields && vm.dataAux.extra.valuesFromLinkedCustomFields.objectParent === 'DOSSIER_TRANSACTION'){
        				TramNewFactory.deleteDossierTransactionCustomFieldGroup(group.groups[index].id).then(function (data) {
        					if(data){
        						var idToDelete = {id: group.groups[index].id};
	        					group.groups.splice(index,1);
	        					deleteToCFs(idToDelete);
	        				}else{
	        					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	        				}
	        			}).catch(function (error) {
	    					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	    					console.error(error);
	    				})
        			}else if(vm.dataAux && vm.dataAux.extra && vm.dataAux.extra.valuesFromLinkedCustomFields && vm.dataAux.extra.valuesFromLinkedCustomFields.objectParent === 'TASK'){
        				TaskFactory.deleteTaskCustomFieldGroup(group.groups[index].id).then(function (data) {
        					if(data){
        						var idToDelete = {id: group.groups[index].id};
	        					group.groups.splice(index,1);
	        					deleteToCFs(idToDelete);
	        				}else{
	        					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	        				}
	        			}).catch(function (error) {
	    					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	    					console.error(error);
	    				})
        			}else if(vm.dataAux && vm.dataAux.extra && vm.dataAux.extra.valuesFromLinkedCustomFields && vm.dataAux.extra.valuesFromLinkedCustomFields.objectParent === 'DOCUMENT'){
        				DccumentsFactory.deleteDocumentCustomFieldGroup(group.groups[index].id).then(function (data) {
        					if(data){
        						var idToDelete = {id: group.groups[index].id};
	        					group.groups.splice(index,1);
	        					deleteToCFs(idToDelete);
	        				}else{
	        					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	        				}
	        			}).catch(function (error) {
	    					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	    					console.error(error);
	    				})
        			}
        		}
        	}
        	vm.addCF = function(group, cf){
        		if(group && group.groups && group.groups.length > 0){
        			var copy = angular.copy(group.groups[0]);
        			deleteValueFromGroups(copy);
        			if(vm.dataAux && vm.dataAux.extra && vm.dataAux.extra.valuesFromLinkedCustomFields && vm.dataAux.extra.valuesFromLinkedCustomFields.objectParent === 'DOSSIER'){
                		TramNewFactory.addDossierCustomFieldGroup(copy).then(function (data) {
	        				if(data){
	        					data.newGroups = createCopyGroups(data);
	        					group.groups.push(data);
	        					addToCFs(data, ((group.groups && group.groups.length > 0)?group.groups[0]: undefined));
	        				}else{
	        					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	        				}
	        			}).catch(function (error) {
	    					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	    					console.error(error);
	    				})
	        		}else if(vm.dataAux && vm.dataAux.extra && vm.dataAux.extra.valuesFromLinkedCustomFields && vm.dataAux.extra.valuesFromLinkedCustomFields.objectParent === 'DOSSIER_TRANSACTION'){
	        			TramNewFactory.addDossierTransactionCustomFieldGroup(copy).then(function (data) {
	        				if(data){
	        					data.newGroups = createCopyGroups(data);
	        					group.groups.push(data);
	        					addToCFs(data, ((group.groups && group.groups.length > 0)?group.groups[0]: undefined));
	        				}else{
	        					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	        				}
	        			}).catch(function (error) {
	    					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	    					console.error(error);
	    				})
	        		}else if(vm.dataAux && vm.dataAux.extra && vm.dataAux.extra.valuesFromLinkedCustomFields && vm.dataAux.extra.valuesFromLinkedCustomFields.objectParent === 'TASK'){
	        			TaskFactory.newTaskCustomFieldGroup(copy).then(function (data) {
	        				if(data){
	        					data.newGroups = createCopyGroups(data);
	        					group.groups.push(data);
	        					addToCFs(data, ((group.groups && group.groups.length > 0)?group.groups[0]: undefined));
	        				}else{
	        					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	        				}
	        			}).catch(function (error) {
	    					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	    					console.error(error);
	    				})
	        		}else if(vm.dataAux && vm.dataAux.extra && vm.dataAux.extra.valuesFromLinkedCustomFields && vm.dataAux.extra.valuesFromLinkedCustomFields.objectParent === 'DOCUMENT'){
	        			DccumentsFactory.newDocumentCustomFieldGroup(copy).then(function (data) {
	        				if(data){
	        					data.newGroups = createCopyGroups(data);
	        					group.groups.push(data);
	        					addToCFs(data, ((group.groups && group.groups.length > 0)?group.groups[0]: undefined));
	        				}else{
	        					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	        				}
	        			}).catch(function (error) {
	    					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	    					console.error(error);
	    				})
	    			}
        		}
        	}
        	vm.deletedCF = function(group, index){
        		if(group && group.groups && group.groups.length > 1 && group.groups.length > index){
        			if(vm.dataAux && vm.dataAux.extra && vm.dataAux.extra.valuesFromLinkedCustomFields && vm.dataAux.extra.valuesFromLinkedCustomFields.objectParent === 'DOSSIER'){
                		TramNewFactory.deleteDossierCustomFieldGroup(group.groups[index].id).then(function (data) {
	        				if(data){
	        					var idToDelete = {id: group.groups[index].id};
	        					group.groups.splice(index,1);
	        					deleteToCFs(idToDelete);
	        				}else{
	        					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	        				}
	        			}).catch(function (error) {
	    					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	    					console.error(error);
	    				});	
        			}else if(vm.dataAux && vm.dataAux.extra && vm.dataAux.extra.valuesFromLinkedCustomFields && vm.dataAux.extra.valuesFromLinkedCustomFields.objectParent === 'DOSSIER_TRANSACTION'){
        				TramNewFactory.deleteDossierTransactionCustomFieldGroup(group.groups[index].id).then(function (data) {
	        				if(data){
	        					var idToDelete = {id: group.groups[index].id};
	        					group.groups.splice(index,1);
	        					deleteToCFs(idToDelete);
	        				}else{
	        					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	        				}
	        			}).catch(function (error) {
	    					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	    					console.error(error);
	    				})
        			}else if(vm.dataAux && vm.dataAux.extra && vm.dataAux.extra.valuesFromLinkedCustomFields && vm.dataAux.extra.valuesFromLinkedCustomFields.objectParent === 'TASK'){
        				TaskFactory.deleteTaskCustomFieldGroup(group.groups[index].id).then(function (data) {
	        				if(data){
	        					var idToDelete = {id: group.groups[index].id};
	        					group.groups.splice(index,1);
	        					deleteToCFs(idToDelete);
	        				}else{
	        					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	        				}
	        			}).catch(function (error) {
	    					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	    					console.error(error);
	    				})
        			}else if(vm.dataAux && vm.dataAux.extra && vm.dataAux.extra.valuesFromLinkedCustomFields && vm.dataAux.extra.valuesFromLinkedCustomFields.objectParent === 'DOCUMENT'){
        				DccumentsFactory.deleteDocumentCustomFieldGroup(group.groups[index].id).then(function (data) {
	        				if(data){
	        					var idToDelete = {id: group.groups[index].id};
	        					group.groups.splice(index,1);
	        					deleteToCFs(idToDelete);
	        				}else{
	        					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	        				}
	        			}).catch(function (error) {
	    					DialogsFactory.error($filter('translate')('global.errors.customFieldError'));
	    					console.error(error);
	    				})
        			}
        		}
        	}
			var calculatedAndDeletedCFsAndGroups = function(dataUPDATE){
				if(dataUPDATE){
                	if(dataUPDATE.showCFs){
						var keys = Object.keys(dataUPDATE.showCFs);
                        _.forEach(keys, function(key){
                        	var indexCFk = $linq(vm.customFields).indexOf("x => x.id == "+key );
                        	if(indexCFk > -1){
                        		if(dataUPDATE.showCFs[key]){
                        			vm.customFields[indexCFk].invisible = true;
            					}else{
            						vm.customFields[indexCFk].invisible = false;
            					}
                        	}
                        });
					}
					if(dataUPDATE.showCFGs){
						var deleteGCFFromGroup = function(groups, key, show){
							var deleted = false;
							if(groups){
								var indexCFk = $linq(groups).indexOf("x => x.id == "+key );
								if(indexCFk > -1){
									if(show){
										groups[indexCFk].invisible = true;
									}else{
										groups[indexCFk].invisible = false;
									}
									deleted = true;
								}else{
									_.forEach(groups, function(cfAux){
										if(!deleted && cfAux.relatedCustomField && cfAux.relatedCustomField.frontendType === 'CF_GROUP'){
											deleted = deleteGCFFromGroup(cfAux.groups, key, show);
										}
									});
								}
							}
							return deleted;
						}
						var keys = Object.keys(dataUPDATE.showCFGs);
                        _.forEach(keys, function(key){
                       		var deleted = false;
       						_.forEach(vm.customFields, function(cfAux){
       							if(!deleted && cfAux.customField && cfAux.customField.frontendType === 'CF_GROUP'){
       								deleted = deleteGCFFromGroup(cfAux.groups, key, dataUPDATE.showCFGs[key]);
       							}
       						});
                        });
					}
                    if(dataUPDATE.calculatedCFs && vm.customFields){
						var keys = Object.keys(dataUPDATE.calculatedCFs);
                        _.forEach(keys, function(key){
                        	var cfToChange = $linq(vm.customFields).firstOrDefault(undefined, "x => x.id == "+key );
        					if(cfToChange){
        						cfToChange.value = dataUPDATE.calculatedCFs[key];
        						CustomFieldFactory.calculateValueJSONCustomField(cfToChange);
        					}
                        });
					}
					if(dataUPDATE.calculatedCFGs){
						var calculateGCFFromGroup = function(groups, key, calculateds){
							var calc = false;
							if(groups){
								var cFk = $linq(groups).firstOrDefault(undefined, "x => x.id == "+key );
								if(cFk){
									cFk.value = calculateds[key];
									CustomFieldFactory.calculateValueJSONGroupCustomField(cFk);
									calc = true;
								}else{
									_.forEach(groups, function(cfAux){
										if(!calc && cfAux.relatedCustomField && cfAux.relatedCustomField.frontendType === 'CF_GROUP'){
											calc = calculateGCFFromGroup(cfAux.groups, key, calculateds);
										}
									});
								}
							}
							return calc;
						}
						var keys = Object.keys(dataUPDATE.calculatedCFGs);
                        _.forEach(keys, function(key){
	 						var calc = false;
    						_.forEach(vm.customFields, function(cfAux){
    							if(!calc && cfAux.customField && cfAux.customField.frontendType === 'CF_GROUP'){
    								calc = calculateGCFFromGroup(cfAux.groups, key, dataUPDATE.calculatedCFGs);
    							}
    						});
                    	});
					}
                }
			}
			
			var addToCFs = function(data, existData){
				if(vm.customFields && data && data.id && existData && existData.id){
					var addCFGroupFromCF = function(groups, key, toAdd){
						var exist = false;
						if(groups){
							var indexCF = $linq(groups).indexOf("x => x.id == "+key);
							if(indexCF > -1){
								groups.push(toAdd);
								exist = true;
							}else{
								_.forEach(groups, function(cfAux){
									if(!exist && cfAux.relatedCustomField && cfAux.relatedCustomField.frontendType === 'CF_GROUP'){
										exist = addCFGroupFromCF(cfAux.groups, key, toAdd);
									}
								});
							}
						}
						return exist;
					}
					
					var exist = false;
					_.forEach(vm.customFields, function(cf){
						if(!exist && cf.customField && cf.customField.frontendType === 'CF_GROUP'){
							exist = addCFGroupFromCF(cf.groups, existData.id, data);
						}
					});
				}
			}
			
			var deleteToCFs = function(data){
				if(vm.customFields && data && data.id){
					var deleteCFGroupFromCF = function(groups, key){
						var exist = false;
						if(groups){
							var indexCF = $linq(groups).indexOf("x => x.id == "+key);
							if(indexCF > -1){
								groups.splice(indexCF,1);
								exist = true;
							}else{
								_.forEach(groups, function(cfAux){
									if(!exist && cfAux.relatedCustomField && cfAux.relatedCustomField.frontendType === 'CF_GROUP'){
										exist = deleteCFGroupFromCF(cfAux.groups, key);
									}
								});
							}
						}
						return exist;
					}
					
					var exist = false;
					_.forEach(vm.customFields, function(cf){
						if(!exist && cf.customField && cf.customField.frontendType === 'CF_GROUP'){
							exist = deleteCFGroupFromCF(cf.groups, data.id);
						}
					});
				}
			}
			this.$onInit = function() {
				var createTabs = function(cf, isGroup){
					var tabs = [];
					var tab ={ heading: 'global.sec.literals.generalData', class: 'col-md-6 col-lg-3 col-xxl-3 tab tabPrincipal text-xs', content: '', select: undefined, deselect: undefined, groupId:cf.id, isGroup: isGroup, cfs:[], canRepeat:false, original:cf};
		        	tabs.push(tab);
					if(cf && cf.newGroups && cf.newGroups.length > 0){
		        		_.forEach(cf.newGroups, function(g){
		        			if(g.isGroup){
		        				var tabG = { heading: g.groups[0].relatedCustomField[vm.languageColumn], class: 'col-md-6 col-lg-3 col-xxl-3 tab tabPrincipal text-xs', content: '', select: undefined, deselect: undefined, groupId:g.id, isGroup: true, cfs:[], canRepeat:g.canRepeat, original:g};
		        				tabs.push(tabG);
		        				tabG.cfs.push({
		        					tabs: createTabs(g.groups[0], true),
		        					group: g,
		        					activeTab:0
		        				});
		        			}else{
		        				tab.cfs.push({group:g});
		        			}
		        		});
		        	}
		        	return tabs;
				}
				vm.customField.newGroups = createGroups(vm.customField);
				vm.groupTabs = createTabs(vm.customField, (((vm.data && vm.data.extra && vm.data.extra.subGroup))?true:false));
				if(vm.data){
					vm.dataAux = angular.copy(vm.data);
					if(vm.dataAux.extra){
						vm.isSubgroup = ((vm.dataAux && vm.dataAux.extra && vm.dataAux.extra.subGroup)?true:false);
						vm.dataAux.extra.subGroup = true;
						vm.isEdit = vm.data.extra.isEdit;
					}
				}
				vm.activeTab = 0;
				vm.actualTab = ((vm.groupTabs && vm.groupTabs.length > vm.activeTab)?vm.groupTabs[vm.activeTab]:undefined);
				vm.groupIdSelected = vm.customField.id+'_'+vm.isSubgroup;
				vm.closeBox = false;
	       }
        }],
        bindings: {
        	customFields: '=',
        	customField: '=',
            data: '='
        }
    })
    .component('annexaObjectCustomFieldsGroupAutomatism', {
        templateUrl: './components/common/annexa-object-custom-fields-group/annexa-object-custom-fields-group-automatism.html',
        controller:['SecFactory', '$filter', 'DialogsFactory', '$rootScope', 'Language', 'AnnexaFormlyFactory', '$scope', '$state', 'GlobalDataFactory','globalModals', 'AnnexaModalFactory', 'SecFactory', '$http', 'HelperService', 
        function(SecFactory, $filter, DialogsFactory, $rootScope, Language, AnnexaFormlyFactory, $scope, $state, GlobalDataFactory, globalModals, AnnexaModalFactory, SecFactory, $http, HelperService) {
    		var vm = this;
        	vm.languageColumn = Language.getActiveColumn();
        	vm.groupsTabs = [];
        	vm.subGroupsTabs = {};
        	vm.activeTab = 0;    
        	vm.isSubgroup = false;
        	vm.actualTab = undefined;
        	vm.closeBox = false;
        	vm.dataAux = {extra:{}};
        	var createGroups = function(cf){
				var newGroups = [];
				if(cf && cf.groups && cf.groups.length > 0){
					_.forEach(cf.groups, function(g){
						var typeValues = [];
        				if(g && g.relatedCustomField){
							if(g.relatedCustomField.backendType === 'STRING'){
	        					typeValues.push({id:"Column",name:$filter("translate")("global.querys.automatisms.column")});
	        					typeValues.push({id:"HybridColumn",name:$filter("translate")("global.querys.automatisms.hybridColumn")});
	        				}else{
	        					typeValues.push({id:"Column",name:$filter("translate")("global.querys.automatisms.column")});
	        				}
	        				typeValues.push({id:g.relatedCustomField.backendType, name:g.relatedCustomField.backendType});
        				}
        				g.typeValues = typeValues;
						g.newGroups = createGroups(g);
						var addedGroup = $linq(newGroups).firstOrDefault(undefined, "x => x.id == "+g.relatedCustomField.id);
						if(addedGroup){
							addedGroup.groups.push(g);
						}else{
							newGroups.push({id: g.relatedCustomField.id, groups:[g], canRepeat: g.canRepeat, isGroup: (g.relatedCustomField.frontendType === 'CF_GROUP')});
						}
					});
				}
				return newGroups;
			}
        	
        	var createCopyGroups = function(cf){
        		var newGroups = [];
				if(cf){
					cf.id = undefined;
					cf.createdDate = undefined;
					cf.value = "";
					cf.typeValue = "";
					if(cf.groups && cf.groups.length > 0){
						_.forEach(cf.groups, function(g){
							g.newGroups = createCopyGroups(g);
							var addedGroup = $linq(newGroups).firstOrDefault(undefined, "x => x.id == "+g.relatedCustomField.id);
							if(addedGroup){
								addedGroup.groups.push(g);
							}else{
								newGroups.push({id: g.relatedCustomField.id, groups:[g], canRepeat: g.canRepeat, isGroup: (g.relatedCustomField.frontendType === 'CF_GROUP')});
							}
						});
					}
				}
				return newGroups;
			}
			        	
        	vm.tabSelected = function(tab){
        		vm.groupIdSelected = ((tab)?tab.groupId+'_'+tab.isGroup:undefined);
        		vm.actualTab = tab;
        	}
        	vm.showBox = function(){
        		vm.closeBox = ((vm.closeBox)?false:true);
        	}
        	
        	vm.addGroup = function(){
        		if(vm.actualTab && vm.actualTab.cfs && vm.actualTab.cfs.length > 0 && vm.actualTab.cfs[0].group && vm.actualTab.cfs[0].group.groups && vm.actualTab.cfs[0].group.groups.length > 0){
        			var copy = angular.copy(vm.actualTab.cfs[0].group.groups[0]);
        			copy.newGroups = createCopyGroups(copy);
        			vm.actualTab.cfs[0].group.groups.push(copy)
        		}
        	}
        	vm.deletedGroup = function(group, index){
        		if(group && group.groups && group.groups.length > 1 && group.groups.length > index){
        			group.groups.splice(index,1);	
        		}
        	}
        	vm.addCF = function(group, cf){
        		if(group && group.groups && group.groups.length > 0){
        			var copy = angular.copy(group.groups[0]);
        			copy.newGroups = createCopyGroups(copy);
        			group.groups.push(copy)
        		}
        	}
        	vm.deletedCF = function(group, index){
        		if(group && group.groups && group.groups.length > 1 && group.groups.length > index){
        			group.groups.splice(index,1);	
        		}
        	}
			
			this.$onInit = function() {
				var createTabs = function(cf, isGroup){
					var tabs = [];
					var tab ={ heading: 'global.sec.literals.generalData', class: 'col-md-6 col-lg-3 col-xxl-3 tab tabPrincipal text-xs', content: '', select: undefined, deselect: undefined, groupId:cf.id, isGroup: isGroup, cfs:[], canRepeat:false, original:cf};
		        	tabs.push(tab);
					if(cf && cf.newGroups && cf.newGroups.length > 0){
		        		_.forEach(cf.newGroups, function(g){
		        			if(g.isGroup){
		        				var tabG = { heading: g.groups[0].relatedCustomField[vm.languageColumn], class: 'col-md-6 col-lg-3 col-xxl-3 tab tabPrincipal text-xs', content: '', select: undefined, deselect: undefined, groupId:g.id, isGroup: true, cfs:[], canRepeat:g.canRepeat, original:g};
		        				tabs.push(tabG);
		        				tabG.cfs.push({
		        					tabs: createTabs(g.groups[0], true),
		        					group: g,
		        					activeTab:0
		        				});
		        			}else{
		        				tab.cfs.push({group:g});
		        			}
		        		});
		        	}
		        	return tabs;
				}
				vm.customField.newGroups = createGroups(vm.customField);
				vm.groupTabs = createTabs(vm.customField, (((vm.data && vm.data.extra && vm.data.extra.subGroup))?true:false));
				if(vm.data){
					vm.dataAux = angular.copy(vm.data);
					if(vm.dataAux.extra){
						vm.isSubgroup = ((vm.dataAux && vm.dataAux.extra && vm.dataAux.extra.subGroup)?true:false);
						vm.dataAux.extra.subGroup = true;
					}
				}
				vm.activeTab = 0;
				vm.actualTab = ((vm.groupTabs && vm.groupTabs.length > vm.activeTab)?vm.groupTabs[vm.activeTab]:undefined);
				vm.groupIdSelected = vm.customField.id+'_'+vm.isSubgroup;
				vm.closeBox = false;
	       }
        }],
        bindings: {
        	customField: '=',
            data: '='
        }
    })