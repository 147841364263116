angular
    .module('annexaApp')
    .component('annexaCustomFieldDefinition', {
        templateUrl: './components/common/annexa-custom-field-definition/annexa-custom-field-definition.html',
        controller:['$rootScope', 'RestService', 'CommonAdminService', 'Language', '$q', '$http', '$filter', 'CommonAdminModals', 'AnnexaFormlyFactory', 'ErrorFactory', 'HelperService', 'GlobalDataFactory', 'CustomFieldFactory', 'QueryFactory', 'CommonService', function ($rootScope, RestService, CommonAdminService, Language, $q, $http, $filter, CommonAdminModals, AnnexaFormlyFactory, ErrorFactory, HelperService, GlobalDataFactory, CustomFieldFactory, QueryFactory, CommonService) {
            var vm = this;
			vm.showCustomFieldType = false;
			vm.isCustomFieldOpen = false; 
            vm.addedCustomField = undefined;
            vm.allCustomFields = [];
            vm.languageColumn = Language.getActiveColumn();
            vm.descriptionLanguageColumn = "descriptionLanguage" + vm.languageColumn.substr(vm.languageColumn.length - 1);
			vm.transactionTypes = [];
			vm.customFieldRequiredList = [
                { id: 'OPTIONAL', description: 'global.literals.OPTIONAL' },
                { id: 'REQUIRED', description: 'global.literals.REQUIRED' },
                { id: 'NO_EDITABLE', description: 'global.literals.NO_EDITABLE' }
            ];
			vm.customFieldProcedureType = [
                { id: 'DOSSIER', description: 'global.customFieldsTypes.DOSSIER' },
                { id: 'ALL_DOSSIER_TRANSACTIONS', description: 'global.customFieldsTypes.ALL_DOSSIER_TRANSACTIONS' },
                { id: 'ALL_DOSSIER_TRANSACTIONS_WITH_COPY', description: 'global.customFieldsTypes.ALL_DOSSIER_TRANSACTIONS_WITH_COPY' },
                { id: 'DOSSIER_TRANSACTIONS_TYPE', description: 'global.customFieldsTypes.DOSSIER_TRANSACTIONS_TYPE' },
				{ id: 'DOSSIER_TRANSACTIONS_TYPE_WITH_COPY', description: 'global.customFieldsTypes.DOSSIER_TRANSACTIONS_TYPE_WITH_COPY' },
                { id: 'DOSSIER_AND_ALL_DOSSIER_TRANSACTIONS', description: 'global.customFieldsTypes.DOSSIER_AND_ALL_DOSSIER_TRANSACTIONS' },
                { id: 'DOSSIER_AND_ALL_DOSSIER_TRANSACTIONS_WITH_COPY', description: 'global.customFieldsTypes.DOSSIER_AND_ALL_DOSSIER_TRANSACTIONS_WITH_COPY' },
                { id: 'DOSSIER_AND_DOSSIER_TRANSACTIONS_TYPE', description: 'global.customFieldsTypes.DOSSIER_AND_DOSSIER_TRANSACTIONS_TYPE' },
                { id: 'DOSSIER_AND_DOSSIER_TRANSACTIONS_TYPE_WITH_COPY', description: 'global.customFieldsTypes.DOSSIER_AND_DOSSIER_TRANSACTIONS_TYPE_WITH_COPY' }

            ];
			
			

            var addCF = function(newCustomField) {
                var addGroups = function(groups){
                	if(groups && groups.length > 0){
                		var groupsCreated = [];
	                	_.forEach(groups, function(val){
	                		groupsCreated.push({
	                			relatedCustomField: val.relatedCustomField,
	                            required: false,
	                            requiredForEndDossier: false,
	                            hiddenField: false,
	                            noEditable: false,
	                            canRepeat: false,
	                            conditional: false,
	                            spel:undefined,
	                            viewOrder: val.viewOrder,
	                            requiredString: 'OPTIONAL',
	                            value: '',
	                            descriptionLanguage1: val.relatedCustomField.descriptionLanguage1,
	                            descriptionLanguage2: val.relatedCustomField.descriptionLanguage2,
	                            descriptionLanguage3: val.relatedCustomField.descriptionLanguage3,
	                            viewOrder: val.viewOrder,
	                            groups: addGroups(((val.relatedCustomField && val.relatedCustomField.groups)?val.relatedCustomField.groups:[]))
	                        });
	                	});
	                	return groupsCreated;
                	}else{
                		return [];
                	}
                }
            	var toAddCustomField = {
                    customField: newCustomField,
                    required: false,
                    requiredForEndDossier: false,
                    hiddenField: false,
                    noEditable: false,
                    canPublish: false,
                    conditional: false,
                    spel:undefined,
                    viewOrder: ((vm.customFields && vm.customFields.length > 0)?$linq(vm.customFields).max("x => x.viewOrder")+1:0),
                    requiredString: 'OPTIONAL',
                    value: '',
                    descriptionLanguage1: newCustomField.descriptionLanguage1,
                    descriptionLanguage2: newCustomField.descriptionLanguage2,
                    descriptionLanguage3: newCustomField.descriptionLanguage3
                }
				if(vm.type == 'PROCEDURE' && vm.showCustomFieldType && !toAddCustomField.customFieldType){
					toAddCustomField.customFieldType = 'ALL_DOSSIER_TRANSACTIONS';
				}
                
                if(newCustomField.groups && newCustomField.groups.length > 0){
                	toAddCustomField.groups = addGroups(newCustomField.groups);
                }
                if(vm.updateOnChange && vm.parentProperty && vm.parentId) {
                    toAddCustomField[vm.parentProperty] = { id: vm.parentId }
                    vm.update(toAddCustomField);
                } else if(vm.type == 'PROCEDURE') {
                	$rootScope.$broadcast('AnnexaProcedureCustomFieldUpdated', { item: toAddCustomField });
                }
                vm.customFields.push(toAddCustomField);
                return toAddCustomField;
            }
            
            vm.toggleCustomField = function(customField) {
			    customField.isCustomFieldOpen = !customField.isCustomFieldOpen;
			};

            vm.editCustomFieldFromObject = function(index){
            	var saveParentCustomFieldFunction = function(){
            		var self = this;
	                var data = this.annexaFormly.model.modal_body;
	                if(data && this.extra && this.extra.index != undefined && this.extra.customFieldParent){
	                	var modify = angular.copy(this.extra.customFieldParent);
	                	modify.requiredString = data.requiredString;
	                	modify.conditional = data.conditional;
	                	if(modify.conditional){
	                		modify.spel = data.spel;
	                		modify.spelDossier = data.spelDossier;
	                	}else{
	                		modify.spel = undefined;
	                		modify.spelDossier = undefined;
	                	}
	                	modify.canPublish = data.canPublish;
	                	modify.calculated = data.calculated;
	                	if(modify.calculated){
	                		modify.spelCalculated = data.spelCalculated;
	                		modify.spelCalculatedDossier = data.spelCalculatedDossier;
	                		modify.dynamicallyCalculated = data.dynamicallyCalculated;
	                	}else{
	                		modify.spelCalculated = undefined;
	                		modify.spelCalculatedDossier = undefined;
	                		modify.dynamicallyCalculated = false;
	                	}
	                	modify.customFieldType = data.customFieldType;
	                	modify.transactionTypes = [];
	                	if(data.transactionTypesAux){
	                		_.forEach(data.transactionTypesAux, function(tta){
	                			modify.transactionTypes.push({transactionType:{id:tta}});
	                		});
	                	}
	                	modify.descriptionLanguage1 = data.descriptionLanguage1;
	                	modify.descriptionLanguage2 = data.descriptionLanguage2;
	                	modify.descriptionLanguage3 = data.descriptionLanguage3;
	                	if(data.columnsQuery){
	                		modify.identifierField = data.columnsQuery.identifier;
	                		if(data.columnsQuery.labelProp && data.columnsQuery.labelProp.length > 0){
	                			var lbl = '';
	                			_.forEach(data.columnsQuery.labelProp, function(lp){
	                				lbl = lbl + ((lbl)?'--@--@--'+lp:lp);
	                			});
	                			modify.labelPropField = lbl;
	                		}else{
	                			modify.labelPropField = '';
	                		}
	                		modify.labelFieldsSeparator = data.columnsQuery.labelFieldsSeparator;
	                		modify.parentField = data.columnsQuery.parent;
	                	}
	                	var auxqp = [];
	                	if(modify.queryParams){
	                		auxqp = angular.copy(modify.queryParams);
	                	}
	                	modify.queryParams = [];
	                	if(data.queryParams){
	                		var keys = Object.keys(data.queryParams);
	                		_.forEach(keys, function(key){
	                			if(!key.endsWith('_Type_Param_Modal')){
	                            	var param = {};
	                            	if(auxqp){
	                            		param = $linq(auxqp).firstOrDefault(undefined, "x => x.parameter == '"+key+"'");
	                            		if(param){
	                            			param.parameterValue = data.queryParams[key];
	                            			param.parameterType = data.queryParams[key+"_Type_Param_Modal"];
	                            			modify.queryParams.push(param);
	                            		}else{
	                            			param = {parameter: key, parameterValue: data.queryParams[key], parameterType: data.queryParams[key+"_Type_Param_Modal"]};
	                            			modify.queryParams.push(param);
	                            		}
	                            	}else{
	                            		param = {parameter: key, parameterValue: data.queryParams[key], parameterType: data.queryParams[key+"_Type_Param_Modal"]};
	                            		modify.queryParams = [];
	                            		modify.queryParams.push(param);
	                            	}
	                			}else if(!data.queryParams[key.substring(0, key.indexOf('_Type_Param_Modal'))]){
	                				var param = {};
	                            	if(auxqp){
	                            		param = $linq(auxqp).firstOrDefault(undefined, "x => x.parameter == '"+key.substring(0, key.indexOf('_Type_Param_Modal'))+"'");
	                            		if(param){
	                            			param.parameterValue = undefined;
	                            			param.parameterType = data.queryParams[key];
	                            			modify.queryParams.push(param);
	                            		}else{
	                            			param = {parameter: key.substring(0, key.indexOf('_Type_Param_Modal')), parameterValue: undefined, parameterType: data.queryParams[key]};
	                            			modify.queryParams.push(param);
	                            		}
	                            	}else{
	                            		param = {parameter: key.substring(0, key.indexOf('_Type_Param_Modal')), parameterValue: undefined, parameterType: data.queryParams[key]};
	                            		modify.queryParams = [];
	                            		modify.queryParams.push(param);
	                            	}
	                			}
                            });
	                	}
	                	var auxrcf = [];
	                	if(modify.relatedCustomFields){
	                		auxrcf = angular.copy(modify.relatedCustomFields);
	                	}
	                	modify.relatedCustomFields = [];
	                	if(data.relatedCustomFields){
	                		var keys = Object.keys(data.relatedCustomFields);
                            _.forEach(keys, function(key){
                            	var param = {};
                            	if(auxrcf){
                            		param = $linq(auxrcf).firstOrDefault(undefined, "x => x.customFieldTemplateTag == '"+key+"'");
                            		if(param){
                            			param.customFieldValue = data.relatedCustomFields[key];
                            			modify.relatedCustomFields.push(param);
                            		}else{
                            			param = {customFieldTemplateTag: key, customFieldValue: data.relatedCustomFields[key]};
                            			modify.relatedCustomFields.push(param);
                            		}
                            	}else{
                            		param = {customFieldTemplateTag: key, customFieldValue: data.relatedCustomFields[key]};
                            		modify.relatedCustomFields = [];
                            		modify.relatedCustomFields.push(param);
                            	}
                            });
	                	}
	                	var cft = $linq(self.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'defaultValue'");
	            		if(cft && cft.data){
	            			modify.value = cft.data.model;
	            		}
	            		var cft = $linq(self.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'configGroup'");
	            		if(cft && cft.data && cft.data.objectGroups){
	            			modify.groups = cft.data.objectGroups;
	            		}else{
	            			modify.groups = [];
	            		}
	                	var onSave = function(data){
	                		vm.customFields[self.extra.index] = modify;
	                		self.close();
	                	};
	                	vm.update(modify, onSave);
	                }else{
	                	self.close();
	                }
	                
            	}
            	if(vm.customFields && vm.customFields.length > index && vm.customFields[index].customField && vm.customFields[index].customField.id && !vm.customFields[index].disabled){
            		var parentValue = -1;
            		var cf = vm.customFields[index];
            		var promises = [];
            		var paraAux = ((cf.queryParams)?angular.copy(cf.queryParams):[]);
	            	var qp = {};
					_.forEach(paraAux, function(param){
						qp[param.parameter] = param.parameterValue;
						qp[param.parameter+"_Type_Param_Modal"] = param.parameterType;
					});
    	            promises.push(RestService.findOne('CustomField', cf.customField.id));
    	            promises.push($http({method: 'GET',url: './api/custom_fields/is_disabled/'+cf.customField.id}));
    	            promises.push($http({method: 'PUT',url: './api/custom_fields/listValues', data: {
    	            	customFieldId: cf.customField.id,
    	            	parentValueId: parentValue,
    	            	additionalFilter: JSON.stringify(qp),
    	            	objectParentId:vm.parentId,
    	            	objectParentType:vm.type,
    	            	inConfig:true
    	            }}));
    	            promises.push($http({method: 'GET',url: './api/custom_fields/getColumnsQuery/'+cf.customField.id}));
    	            $q.all(promises).then(function(data){
    	            	var modal = angular.copy(CommonAdminModals.customFieldsParent);
	            		modal.alerts = [];
	            		modal.annexaFormly.model = {modal_body: angular.copy(cf)};
						modal.annexaFormly.model.modal_body.customField = JSOG.decode(data[0].data);
						modal.annexaFormly.model.modal_body.canEditCustomField = JSOG.decode(data[1].data);
						modal.annexaFormly.model.modal_body.customfFieldParentTypeValue = vm.type;
						modal.annexaFormly.model.modal_body.showCustomfFieldParentTypeValue = vm.showCustomFieldType;
						modal.annexaFormly.model.modal_body.transactionTypesAux = [];
	                	if(modal.annexaFormly.model.modal_body.transactionTypes){
	                		_.forEach(modal.annexaFormly.model.modal_body.transactionTypes, function(tta){
	                			modal.annexaFormly.model.modal_body.transactionTypesAux.push(tta.transactionType.id);
	                		});
	                	}
	                	if(modal.annexaFormly.model.modal_body.taskType && modal.annexaFormly.model.modal_body.taskType.id){
	                		modal.annexaFormly.model.modal_body.taskType = {id: modal.annexaFormly.model.modal_body.taskType.id};
	                	}
	                	var labelPropField = [];
						if(modal.annexaFormly.model.modal_body.labelPropField){
							labelPropField = modal.annexaFormly.model.modal_body.labelPropField.split("--@--@--");
						}
						modal.annexaFormly.model.modal_body.columnsQuery = {
							identifier: modal.annexaFormly.model.modal_body.identifierField,
							labelProp: labelPropField,
							labelFieldsSeparator: modal.annexaFormly.model.modal_body.labelFieldsSeparator,
							parent:modal.annexaFormly.model.modal_body.parentField
						};
						var addedCF = [];
						if(modal.annexaFormly.model.modal_body.customField && modal.annexaFormly.model.modal_body.customField.id){
							addedCF.push(modal.annexaFormly.model.modal_body.customField.id);
						}
						var lvalues = [];
						if(modal.annexaFormly.model.modal_body.customField && modal.annexaFormly.model.modal_body.customField.fromQuery && modal.annexaFormly.model.modal_body.customField.query && modal.annexaFormly.model.modal_body.customField.query.id){
							if(data[2].data && data[2].data.length > 0){
								lvalues = JSOG.decode(data[2].data);
								var lvaluesActual = [];
								if(modal.annexaFormly.model.modal_body.columnsQuery.identifier && modal.annexaFormly.model.modal_body.columnsQuery.labelProp && modal.annexaFormly.model.modal_body.columnsQuery.labelProp.length > 0){
									_.forEach(lvalues, function(lv){
										if(lv[modal.annexaFormly.model.modal_body.columnsQuery.identifier]){
											var label = '';
											_.forEach(modal.annexaFormly.model.modal_body.columnsQuery.labelProp, function(lp){
												label = label + ((label)?((modal.annexaFormly.model.modal_body.columnsQuery.labelFieldsSeparator)?modal.annexaFormly.model.modal_body.columnsQuery.labelFieldsSeparator:'')+" "+((lv[lp])?lv[lp]:''):((lv[lp])?lv[lp]:''));
											});
											if(modal.annexaFormly.model.modal_body.customField.frontendType === 'STRUCTURAL_SELECT' && modal.annexaFormly.model.modal_body.columnsQuery.parent && lv[modal.annexaFormly.model.modal_body.columnsQuery.parent]){
												lvaluesActual.push({id: lv[modal.annexaFormly.model.modal_body.columnsQuery.identifier], description:label, value:lv[modal.annexaFormly.model.modal_body.columnsQuery.identifier], parentValue: {id:lv[modal.annexaFormly.model.modal_body.columnsQuery.parent]}});
											}else{
												lvaluesActual.push({id: lv[modal.annexaFormly.model.modal_body.columnsQuery.identifier], description:label, value:lv[modal.annexaFormly.model.modal_body.columnsQuery.identifier] });
											}
										}
									});
								}
								modal.annexaFormly.model.modal_body.customField.listValues = lvaluesActual;
								modal.annexaFormly.model.modal_body.customField.originalListValues = lvalues;
							}
						}
        				modal.extra = {index: index, customFieldParent:cf}
	            		modal.annexaFormly.options = { watchAllExpressions: true };
	            		var cfl = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'customFieldLabel'");
	            		if(cfl && cfl.templateOptions){
	            			cfl.templateOptions.value = ((modal.annexaFormly.model.modal_body && modal.annexaFormly.model.modal_body.customField && modal.annexaFormly.model.modal_body.customField[vm.languageColumn])?modal.annexaFormly.model.modal_body.customField[vm.languageColumn]:'');
	            			cfl.templateOptions.editObject = function(){
	            				var queryOld = angular.copy(modal.annexaFormly.model.modal_body.customField.query); 
	            				var originalListValuesOld = angular.copy(modal.annexaFormly.model.modal_body.customField.originalListValues); 
	            				var listValuesOld = angular.copy(modal.annexaFormly.model.modal_body.customField.listValues); 
	            				var saveEditCustomField = function(customFieldModified) {
	            					if(modal.annexaFormly.model.modal_body.columnsQuery){
		            					modal.annexaFormly.model.modal_body.columnsQuery.modifyCFFromModal = true;
		            				}
		            				var promises = [];
	                        		promises.push($http({method: 'GET',url: './api/custom_fields/is_disabled/'+cf.customField.id}));
	                        		if(customFieldModified && customFieldModified.fromQuery && customFieldModified.query && customFieldModified.query.id  && (!queryOld || !queryOld.id || (queryOld && queryOld.id && customFieldModified.query.id !== queryOld.id))){
		                	            promises.push($http({method: 'PUT',url: './api/custom_fields/listValues', data: {
		                	            	customFieldId: cf.customField.id,
		                	            	parentValueId: parentValue,
		                	            	additionalFilter: JSON.stringify(qp),
		                	            	objectParentId:vm.parentId,
		                	            	objectParentType:vm.type,
		                	            	inConfig:true
		                	            }}));
		                	            promises.push($http({method: 'GET',url: './api/custom_fields/getColumnsQuery/'+cf.customField.id}));
	                        		}
	                	            $q.all(promises).then(function(data){
		            					if(modal.annexaFormly.model.modal_body.columnsQuery){
			            					modal.annexaFormly.model.modal_body.columnsQuery.modifyCFFromModal = false;
			            				}
	                	            	modal.annexaFormly.model.modal_body.customField = customFieldModified;
	            						modal.annexaFormly.model.modal_body.canEditCustomField = JSOG.decode(data[0].data);
	            						var lvalues = [];
	            						if(modal.annexaFormly.model.modal_body.customField && modal.annexaFormly.model.modal_body.customField.fromQuery && modal.annexaFormly.model.modal_body.customField.query && modal.annexaFormly.model.modal_body.customField.query.id){
	            							if(customFieldModified && customFieldModified.fromQuery && customFieldModified.query && customFieldModified.query.id  && (!queryOld || !queryOld.id || (queryOld && queryOld.id && customFieldModified.query.id !== queryOld.id))){
	            								if(data[1].data && data[1].data.length > 0){
	            									lvalues = JSOG.decode(data[1].data);
	            									var lvaluesActual = [];
	            									if(modal.annexaFormly.model.modal_body.columnsQuery.identifier && modal.annexaFormly.model.modal_body.columnsQuery.labelProp && modal.annexaFormly.model.modal_body.columnsQuery.labelProp.length > 0){
	            										_.forEach(lvalues, function(lv){
	            											if(lv[modal.annexaFormly.model.modal_body.columnsQuery.identifier]){
	            												var label = '';
	            												_.forEach(modal.annexaFormly.model.modal_body.columnsQuery.labelProp, function(lp){listValuesOld
	            													label = label + ((label)?((modal.annexaFormly.model.modal_body.columnsQuery.labelFieldsSeparator)?modal.annexaFormly.model.modal_body.columnsQuery.labelFieldsSeparator:'')+" "+((lv[lp])?lv[lp]:''):((lv[lp])?lv[lp]:''));
	            												});
	            												if(modal.annexaFormly.model.modal_body.customField.frontendType === 'STRUCTURAL_SELECT' && modal.annexaFormly.model.modal_body.columnsQuery.parent && lv[modal.annexaFormly.model.modal_body.columnsQuery.parent]){
	            													lvaluesActual.push({id: lv[modal.annexaFormly.model.modal_body.columnsQuery.identifier], description:label, value:lv[modal.annexaFormly.model.modal_body.columnsQuery.identifier], parentValue: {id:lv[modal.annexaFormly.model.modal_body.columnsQuery.parent]}});
	            												}else{
	            													lvaluesActual.push({id: lv[modal.annexaFormly.model.modal_body.columnsQuery.identifier], description:label, value:lv[modal.annexaFormly.model.modal_body.columnsQuery.identifier] });
	            												}
	            											}
	            										});
	            									}
	            									modal.annexaFormly.model.modal_body.customField.listValues = lvaluesActual;
	            									modal.annexaFormly.model.modal_body.customField.originalListValues = lvalues;
	            								}else{
	            									modal.annexaFormly.model.modal_body.customField.listValues = [];
	            									modal.annexaFormly.model.modal_body.customField.originalListValues = [];
	            								}
	            							}else{
	            								modal.annexaFormly.model.modal_body.customField.listValues = listValuesOld;
            									modal.annexaFormly.model.modal_body.customField.originalListValues = originalListValuesOld;
	            							}
	            						}
	            						var cft = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'defaultValue'");
	            	            		if(cft && cft.data){
	            	            			cft.data.key = ((modal.annexaFormly.model.modal_body.customField && modal.annexaFormly.model.modal_body.customField.id)?modal.annexaFormly.model.modal_body.customField.id:undefined);
	            	            			cft.data.frontendType = ((modal.annexaFormly.model.modal_body.customField && modal.annexaFormly.model.modal_body.customField.frontendType)?modal.annexaFormly.model.modal_body.customField.frontendType:undefined);
	            	            			cft.data.backendType = ((modal.annexaFormly.model.modal_body.customField && modal.annexaFormly.model.modal_body.customField.backendType)?modal.annexaFormly.model.modal_body.customField.backendType:undefined);
	            	            			cft.data.minLength = ((modal.annexaFormly.model.modal_body.customField && modal.annexaFormly.model.modal_body.customField.minLength)?modal.annexaFormly.model.modal_body.customField.minLength:undefined);
	            	            			cft.data.maxLength = ((modal.annexaFormly.model.modal_body.customField && modal.annexaFormly.model.modal_body.customField.maxLength)?modal.annexaFormly.model.modal_body.customField.maxLength:undefined);
	            	            			cft.data.decimalPositions = ((modal.annexaFormly.model.modal_body.customField && modal.annexaFormly.model.modal_body.customField.decimalPositions)?modal.annexaFormly.model.modal_body.customField.decimalPositions:undefined);
	            	            			cft.data.model = modal.annexaFormly.model.modal_body.value;
	            	            			cft.data.cfDisabled = false;
	            	            			if(modal.annexaFormly.model.modal_body.customField && modal.annexaFormly.model.modal_body.customField.fromQuery && modal.annexaFormly.model.modal_body.customField.query && modal.annexaFormly.model.modal_body.customField.query.id){
	            	            				cft.data.optionLabelProp = "description";
	            	            			}else{
	            	            				cft.data.optionLabelProp = vm.languageColumn;
	            	            			}
	            	            			cft.data.label = $filter('translate')('global.literals.defaultValue');
	            	            			cft.data.options = ((modal.annexaFormly.model.modal_body.customField && modal.annexaFormly.model.modal_body.customField.listValues)?modal.annexaFormly.model.modal_body.customField.listValues:[]);
	            	            		}
	            	            		if(customFieldModified && customFieldModified.fromQuery && customFieldModified.query && customFieldModified.query.id  && (!queryOld || !queryOld.id || (queryOld && queryOld.id && customFieldModified.query.id !== queryOld.id))){
	            	            			var columnsAux = ((data[2] && data[2].data)?JSOG.decode(data[2].data):[]);
	            	            			if(columns){
	            	            				columns.length = 0;
	            	            				if(columnsAux && columnsAux.length > 0){
	            	            					_.forEach(columnsAux, function(col){
	            	            						columns.push({id:col, description:col});
	            	            					});
	            	            				}
	            	            			}
	            	            			var cft = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'columnsQuery'");
	            		            		if(cft && cft.fieldGroup){
	            		            			modal.annexaFormly.model.modal_body.columnsQuery = {};
	            		            			var cftIdent = $linq(cft.fieldGroup).firstOrDefault(undefined, "x => x.key == 'identifier'");
	            			            		if(cftIdent && cftIdent.templateOptions){
	            			            			cftIdent.templateOptions.options = columns;
	            			            		}
	            			            		var cftLP = $linq(cft.fieldGroup).firstOrDefault(undefined, "x => x.key == 'labelProp'");
	            			            		if(cftLP && cftLP.templateOptions){
	            			            			cftLP.templateOptions.options = columns;
	            			            		}
	            			            		var cftparent = $linq(cft.fieldGroup).firstOrDefault(undefined, "x => x.key == 'parent'");
	            			            		if(cftparent && cftparent.templateOptions){
	            			            			cftparent.templateOptions.options = columns;
	            			            		}
	            		            		}
	            	            			var cft = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'queryParams'");
	            	            			modal.annexaFormly.model.modal_body.queryParams = {};
	            	            			cft.fieldGroup.length = 0;
	            		            		if(cft && modal.annexaFormly.model.modal_body.customField && modal.annexaFormly.model.modal_body.customField.fromQuery && modal.annexaFormly.model.modal_body.customField.query && modal.annexaFormly.model.modal_body.customField.query.id && modal.annexaFormly.model.modal_body.customField.query.parameters && modal.annexaFormly.model.modal_body.customField.query.parameters.length > 0 ){
	            			            		var parameters = $linq(modal.annexaFormly.model.modal_body.customField.query.parameters).orderBy("x => x.viewOrder").toArray();
	            								_.forEach(parameters, function(param){
	            									if(param && param.queryParameter){
	            										var typesParam = [];
	            										typesParam.push({id:'##MANUAL##', description:"global.literals.manual"});
	            										typesParam.push({id:'##PARENT##', description:"global.literals.father"});
	            										_.forEach(vm.customFields, function(cf){
	            											typesParam.push({id:cf.customField.templateTag, description:cf.customField[vm.languageColumn]});
	            										});
	            										cft.fieldGroup.push({
	            	    									key: param.queryParameter.alias+"_Type_Param_Modal",
	            	    									type: 'annexaSelectRow',
	            	    									className: 'col-sm-6 m-b-xs',
	            	    									templateOptions: {
	            	    										optionsAttr: 'bs-options',
	            	    										ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	            	    										label: param.queryParameter[vm.languageColumn]+" "+$filter("translate")("global.literals.type"),
	            	    										valueProp: 'id',
	            	    										labelProp: 'description',
	            	    										placeholder: '',
	            	    										options: typesParam,
	            	    										required: true
	            	    									},
	            	    									data: {
	            	    										row: true,
	            	    										informed: true,
	            	    										colClass: ' col-xs-12',
	            	    										labelClass: 'label-strong',
	            	    										clear: function($event,model,key, $select) {
	            	    											$event.stopPropagation();
	            	    											model[key] = undefined;
	            	    											if($select) {
	            	    												$select.selected = undefined;
	            	    												$select.search = undefined;
	            	    											}
	            	    										}
	            	    									}
	            	    								});
	            										fieldQueryCreated = QueryFactory.createQueryParameterField(param, false, false, 'col-sm-6');
	            										if(fieldQueryCreated){
	            											fieldQueryCreated.hideExpression = function ($viewValue, $modelValue, scope) {
	            												if(scope.model && scope.model[param.queryParameter.alias+"_Type_Param_Modal"] && scope.model[param.queryParameter.alias+"_Type_Param_Modal"] === "##MANUAL##"){
	            													return false;
	            												}else{
	            				  	                                return true;
	            				                                }
	            					                      	}
	            										}
	            										cft.fieldGroup.push(fieldQueryCreated);
	            										modal.annexaFormly.model.modal_body.queryParams[param.queryParameter.alias+"_Type_Param_Modal"] = "##MANUAL##";
	            									}
	            								});
	            		            		}
		            	            		var cft = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'relatedCustomFields'");
		            	            		modal.annexaFormly.model.modal_body.relatedCustomFields = {};
		            	            		cft.fieldGroup.length = 0;
	            		            	}else if(customFieldModified && (!customFieldModified.fromQuery || (!customFieldModified.query && !customFieldModified.query.id))){
	            		            		var cft = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'queryParams'");
	            	            			modal.annexaFormly.model.modal_body.queryParams = {};
	            	            			cft.fieldGroup.length = 0;
	            	            			var cft = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'relatedCustomFields'");
		            	            		modal.annexaFormly.model.modal_body.relatedCustomFields = {};
		            	            		cft.fieldGroup.length = 0;
	            		            	}
	                	            });
	                        	}
	            				CustomFieldFactory.customFieldModal(modal.annexaFormly.model.modal_body.customField.id, saveEditCustomField);
	            			} 
	            		}
	            		var rString = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'requiredString'");
	            		if(rString && rString.templateOptions){
	            			rString.templateOptions.options = vm.customFieldRequiredList;
	            		}
	            		var cft = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'customFieldType'");
	            		if(cft && cft.templateOptions){
	            			cft.templateOptions.options = vm.customFieldProcedureType;
	            		}
	            		if(vm.type == 'PROCEDURE'){
		            		var spel = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'spel'");
		    				if(spel){
		    					spel.templateOptions.label = 'global.literals.conditionDossierTransaction';
		    				}
	            			var spel = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'spelCalculated'");
		    				if(spel){
		    					spel.templateOptions.label = 'global.literals.conditionCalculatedDossierTransaction';
		    				}
	            		}
	            		var executeSpel = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'executeSpel'");
	    				if(executeSpel){
	    					executeSpel.templateOptions.executeFunction = function() {
	    						var params = [];
	    						if(vm.type === 'PROCEDURE'){
	    							params.push({name:'dossier', type:{id:'Dossier',name:'Dossier'}, id:''});
	    							params.push({name:'transaction', type:{id:'DossierTransaction',name:'DossierTransaction'}, id:''});
	    							params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
	    							if(vm.customFields){
	    								_.forEach(vm.customFields, function(cf){
	    									if(cf.customFieldType && cf.customFieldType != 'DOSSIER'){
	    										params.push({name:cf.customField.templateTag, type:{id:'ProcedureCustomField',name:'ProcedureCustomField'}, id:''});
	    									}
	    								});
	    							}
	    						}else if(vm.type === 'TASK_TYPE'){
	    							params.push({name:'task', type:{id:'Task',name:'Task'}, id:''});
	    							params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
	    							if(vm.customFields){
	    								_.forEach(vm.customFields, function(cf){
	    									params.push({name:cf.customField.templateTag, type:{id:'TaskTypeCustomField',name:'TaskTypeCustomField'}, id:''});
	    								});
	    							}
	    						}else if(vm.type === 'DOCUMENT_TYPE'){
	    							params.push({name:'document', type:{id:'Document',name:'Document'}, id:''});
	    							params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
	    							if(vm.customFields){
	    								_.forEach(vm.customFields, function(cf){
	    									params.push({name:cf.customField.templateTag, type:{id:'TaskTypeCustomField',name:'TaskTypeCustomField'}, id:''});
	    								});
	    							}
	    						}else if(vm.type === 'TRANSACTION_TYPE'){
	    							params.push({name:'dossier', type:{id:'Dossier',name:'Dossier'}, id:''});
	    							params.push({name:'transaction', type:{id:'DossierTransaction',name:'DossierTransaction'}, id:''});
	    							params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
	    							if(vm.customFields){
	    								_.forEach(vm.customFields, function(cf){
	    									params.push({name:cf.customField.templateTag, type:{id:'TransactionTypeCustomField',name:'TransactionTypeCustomField'}, id:''});
	    								});
	    							}
		    					}
	    						CommonService.executeExpression(((modal && modal.annexaFormly && modal.annexaFormly.model.modal_body.spel)?modal.annexaFormly.model.modal_body.spel:undefined), params);	
	    		        	};
	    				}
	    				var executeSpelDossier = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'executeSpelDossier'");
	    				if(executeSpelDossier){
	    					executeSpelDossier.templateOptions.executeFunction = function() {
	    						var params = [];
	    						params.push({name:'dossier', type:{id:'Dossier',name:'Dossier'}, id:''});
	    						params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
	    						if(vm.customFields){
    								_.forEach(vm.customFields, function(cf){
    									if(cf.customFieldType && cf.customFieldType != 'DOSSIER_TRANSACTIONS_TYPE' && cf.customFieldType != 'DOSSIER_TRANSACTIONS_TYPE_WITH_COPY' && cf.customFieldType != 'ALL_DOSSIER_TRANSACTIONS' && cf.customFieldType != 'ALL_DOSSIER_TRANSACTIONS_WITH_COPY'){
    										params.push({name:cf.customField.templateTag, type:{id:'ProcedureCustomField',name:'ProcedureCustomField'}, id:''});
    									}
    								});
    							}
	    						CommonService.executeExpression(((modal && modal.annexaFormly && modal.annexaFormly.model.modal_body.spelDossier)?modal.annexaFormly.model.modal_body.spelDossier:undefined), params);	
	    		        	};
	    				}
	    				var executeSpelCalculated = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'executeSpelCalculated'");
	    				if(executeSpelCalculated){
	    					executeSpelCalculated.templateOptions.executeFunction = function() {
	    						var params = [];
	    						if(vm.type === 'PROCEDURE'){
	    							params.push({name:'dossier', type:{id:'Dossier',name:'Dossier'}, id:''});
	    							params.push({name:'transaction', type:{id:'DossierTransaction',name:'DossierTransaction'}, id:''});
	    							params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
	    							if(vm.customFields){
	    								_.forEach(vm.customFields, function(cf){
	    									if(cf.customFieldType && cf.customFieldType != 'DOSSIER'){
	    										params.push({name:cf.customField.templateTag, type:{id:'ProcedureCustomField',name:'ProcedureCustomField'}, id:''});
	    									}
	    								});
	    							}
	    						}else if(vm.type === 'TASK_TYPE'){
	    							params.push({name:'task', type:{id:'Task',name:'Task'}, id:''});
	    							params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
	    							if(vm.customFields){
	    								_.forEach(vm.customFields, function(cf){
	    									params.push({name:cf.customField.templateTag, type:{id:'TaskTypeCustomField',name:'TaskTypeCustomField'}, id:''});
	    								});
	    							}
	    						}else if(vm.type === 'DOCUMENT_TYPE'){
	    							params.push({name:'document', type:{id:'Document',name:'Document'}, id:''});
	    							params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
	    							if(vm.customFields){
	    								_.forEach(vm.customFields, function(cf){
	    									params.push({name:cf.customField.templateTag, type:{id:'DocumentTypeCustomField',name:'DocumentTypeCustomField'}, id:''});
	    								});
	    							}
	    						}else if(vm.type === 'TRANSACTION_TYPE'){
	    							params.push({name:'dossier', type:{id:'Dossier',name:'Dossier'}, id:''});
	    							params.push({name:'transaction', type:{id:'DossierTransaction',name:'DossierTransaction'}, id:''});
	    							params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
	    							if(vm.customFields){
	    								_.forEach(vm.customFields, function(cf){
	    									params.push({name:cf.customField.templateTag, type:{id:'ProcedureCustomField',name:'ProcedureCustomField'}, id:''});
	    								});
	    							}
		    					}
	    						CommonService.executeExpression(((modal && modal.annexaFormly && modal.annexaFormly.model.modal_body.spelCalculated)?modal.annexaFormly.model.modal_body.spelCalculated:undefined), params);	
	    		        	};
	    				}
	    				var executeSpelCalculatedDossier = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'executeSpelCalculatedDossier'");
	    				if(executeSpelCalculatedDossier){
	    					executeSpelCalculatedDossier.templateOptions.executeFunction = function() {
	    						var params = [];
	    						params.push({name:'dossier', type:{id:'Dossier',name:'Dossier'}, id:''});
	    						params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
								_.forEach(vm.customFields, function(cf){
									if(cf.customFieldType && cf.customFieldType != 'DOSSIER_TRANSACTIONS_TYPE' && cf.customFieldType != 'DOSSIER_TRANSACTIONS_TYPE_WITH_COPY' && cf.customFieldType != 'ALL_DOSSIER_TRANSACTIONS' && cf.customFieldType != 'ALL_DOSSIER_TRANSACTIONS_WITH_COPY'){
										params.push({name:cf.customField.templateTag, type:{id:'ProcedureCustomField',name:'ProcedureCustomField'}, id:''});
									}
								});
	    						CommonService.executeExpression(((modal && modal.annexaFormly && modal.annexaFormly.model.modal_body.spelCalculatedDossier)?modal.annexaFormly.model.modal_body.spelCalculatedDossier:undefined), params);	
	    		        	};
	    				}
	            		var cft = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'defaultValue'");
	            		if(cft && cft.data){
	            			cft.data.key = ((modal.annexaFormly.model.modal_body.customField && modal.annexaFormly.model.modal_body.customField.id)?modal.annexaFormly.model.modal_body.customField.id:undefined);
	            			cft.data.frontendType = ((modal.annexaFormly.model.modal_body.customField && modal.annexaFormly.model.modal_body.customField.frontendType)?modal.annexaFormly.model.modal_body.customField.frontendType:undefined);
	            			cft.data.backendType = ((modal.annexaFormly.model.modal_body.customField && modal.annexaFormly.model.modal_body.customField.backendType)?modal.annexaFormly.model.modal_body.customField.backendType:undefined);
	            			cft.data.minLength = ((modal.annexaFormly.model.modal_body.customField && modal.annexaFormly.model.modal_body.customField.minLength)?modal.annexaFormly.model.modal_body.customField.minLength:undefined);
	            			cft.data.maxLength = ((modal.annexaFormly.model.modal_body.customField && modal.annexaFormly.model.modal_body.customField.maxLength)?modal.annexaFormly.model.modal_body.customField.maxLength:undefined);
	            			cft.data.decimalPositions = ((modal.annexaFormly.model.modal_body.customField && modal.annexaFormly.model.modal_body.customField.decimalPositions)?modal.annexaFormly.model.modal_body.customField.decimalPositions:undefined);
	            			cft.data.model = modal.annexaFormly.model.modal_body.value;
	            			cft.data.cfDisabled = false;
	            			if(modal.annexaFormly.model.modal_body.customField && modal.annexaFormly.model.modal_body.customField.fromQuery && modal.annexaFormly.model.modal_body.customField.query && modal.annexaFormly.model.modal_body.customField.query.id){
	            				cft.data.optionLabelProp = "description";
	            			}else{
	            				cft.data.optionLabelProp = vm.languageColumn;
	            			}
	            			cft.data.label = $filter('translate')('global.literals.defaultValue');
	            			cft.data.options = ((modal.annexaFormly.model.modal_body.customField && modal.annexaFormly.model.modal_body.customField.listValues)?modal.annexaFormly.model.modal_body.customField.listValues:[]);
	            		}
	            		var columnsAux = ((data[3] && data[3].data)?JSOG.decode(data[3].data):[]);
	            		var columns = [];
	            		if(columnsAux && columnsAux.length > 0){
	            			_.forEach(columnsAux, function(col){
	            				columns.push({id:col, description:col});
	            			});
	            		}
	            		var cft = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'queryParams'");
	            		var paraAux = ((modal.annexaFormly.model.modal_body.queryParams)?angular.copy(modal.annexaFormly.model.modal_body.queryParams):[]);
	            		modal.annexaFormly.model.modal_body.queryParams = {};
	            		if(cft && modal.annexaFormly.model.modal_body.customField && modal.annexaFormly.model.modal_body.customField.fromQuery && modal.annexaFormly.model.modal_body.customField.query && modal.annexaFormly.model.modal_body.customField.query.id && modal.annexaFormly.model.modal_body.customField.query.parameters && modal.annexaFormly.model.modal_body.customField.query.parameters.length > 0 ){
		            		var parameters = $linq(modal.annexaFormly.model.modal_body.customField.query.parameters).orderBy("x => x.viewOrder").toArray();
							_.forEach(parameters, function(param){
								if(param && param.queryParameter){
									var typesParam = [];
									typesParam.push({id:'##MANUAL##', description:"global.literals.manual"});
									typesParam.push({id:'##PARENT##', description:"global.literals.father"});
									_.forEach(vm.customFields, function(cf){
										typesParam.push({id:cf.customField.templateTag, description:cf.customField[vm.languageColumn]});
									});
									cft.fieldGroup.push({
    									key: param.queryParameter.alias+"_Type_Param_Modal",
    									type: 'annexaSelectRow',
    									className: 'col-sm-6 m-b-xs',
    									templateOptions: {
    										optionsAttr: 'bs-options',
    										ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
    										label: param.queryParameter[vm.languageColumn]+" "+$filter("translate")("global.literals.type"),
    										valueProp: 'id',
    										labelProp: 'description',
    										placeholder: '',
    										options: typesParam,
    										required: true
    									},
    									data: {
    										row: true,
    										informed: true,
    										colClass: ' col-xs-12',
    										labelClass: 'label-strong',
    										clear: function($event,model,key, $select) {
    											$event.stopPropagation();
    											model[key] = undefined;
    											if($select) {
    												$select.selected = undefined;
    												$select.search = undefined;
    											}
    										}
    									}
    								});
									fieldQueryCreated = QueryFactory.createQueryParameterField(param, false, false, 'col-sm-6');
									if(fieldQueryCreated){
										fieldQueryCreated.hideExpression = function ($viewValue, $modelValue, scope) {
											if(scope.model && scope.model[param.queryParameter.alias+"_Type_Param_Modal"] && scope.model[param.queryParameter.alias+"_Type_Param_Modal"] === "##MANUAL##"){
												return false;
											}else{
			  	                                return true;
			                                }
				                      	}
									}
									cft.fieldGroup.push(fieldQueryCreated);
									var qpaux = ((paraAux)?$linq(paraAux).firstOrDefault(undefined, "x => x.parameter == '"+param.queryParameter.alias+"'"):undefined);
									modal.annexaFormly.model.modal_body.queryParams[param.queryParameter.alias+"_Type_Param_Modal"] = ((qpaux && qpaux.parameterType)?qpaux.parameterType:"##MANUAL##");
									if(param.queryParameter && param.queryParameter.queryParameterType  == 'DATE' && qpaux && qpaux.parameterValue){
										modal.annexaFormly.model.modal_body.queryParams[param.queryParameter.alias] = qpaux.parameterValue;
					                }else  if (param.queryParameter && param.queryParameter.queryParameterType == 'INTEGER' && qpaux && qpaux.parameterValue) {
					                	modal.annexaFormly.model.modal_body.queryParams[param.queryParameter.alias] = parseInt(qpaux.parameterValue);
					                } else if (param.queryParameter && param.queryParameter.queryParameterType == 'DOUBLE'  && qpaux && qpaux.parameterValue) {
				                        var valueLiteral_parts = (qpaux.parameterValue).split('.');
				                        if(valueLiteral_parts && valueLiteral_parts.length > 0){
				                            if(valueLiteral_parts && valueLiteral_parts.length > 1 && valueLiteral_parts[1]){
				                            	modal.annexaFormly.model.modal_body.queryParams[param.queryParameter.alias] = parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(2));
				                            }else{
				                            	modal.annexaFormly.model.modal_body.queryParams[param.queryParameter.alias] = parseFloat(parseFloat(valueLiteral_parts[0]).toLocaleString() + ".00");
				                            }
				                        }
					                } else if(param.queryParameter && (param.queryParameter.queryParameterType == 'BOOLEAN' || param.queryParameter.queryParameterType == 'BOOLEAN_NULL') && qpaux && qpaux.parameterValue){
					                	modal.annexaFormly.model.modal_body.queryParams[param.queryParameter.alias] = qpaux.parameterValue;
					                }
								}
							});
	            		}
	            		var cft = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'columnsQuery'");
	            		if(cft && cft.fieldGroup){
	            			var cftIdent = $linq(cft.fieldGroup).firstOrDefault(undefined, "x => x.key == 'identifier'");
		            		if(cftIdent && cftIdent.templateOptions){
		            			cftIdent.templateOptions.options = columns;
		            		}
		            		var cftLP = $linq(cft.fieldGroup).firstOrDefault(undefined, "x => x.key == 'labelProp'");
		            		if(cftLP && cftLP.templateOptions){
		            			cftLP.templateOptions.options = columns;
		            		}
		            		var cftparent = $linq(cft.fieldGroup).firstOrDefault(undefined, "x => x.key == 'parent'");
		            		if(cftparent && cftparent.templateOptions){
		            			cftparent.templateOptions.options = columns;
		            		}
	            		}
	            		var cft = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'addRelatedCustomField'");
	            		if(cft && cft.templateOptions){
	            			cft.templateOptions.executeFunction = function(){
	            				var saveCustomFieldRelatedFunction = function(){
	            					if(this.annexaFormly && this.annexaFormly.model && this.annexaFormly.model.modal_body && this.annexaFormly.model.modal_body.relatedCustomField && this.annexaFormly.model.modal_body.relatedQueryColumn){
	            						var cfAux = $linq(vm.customFields).select("x => x.customField").firstOrDefault(undefined, "x => x.id =="+this.annexaFormly.model.modal_body.relatedCustomField);
	            						if(cfAux && cfAux.templateTag){
	            							var cfRt = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'relatedCustomFields'");
	            							if(cfRt && cfRt.fieldGroup){
	            								addedCF.push(cfAux.id);
	            								cfRt.fieldGroup.push({
	            									key: cfAux.templateTag,
	            									type: 'annexaSelectRow',
	            									className: 'col-sm-4 m-b-xs',
	            									templateOptions: {
	            										optionsAttr: 'bs-options',
	            										ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	            										label: cfAux[vm.languageColumn],
	            										valueProp: 'id',
	            										labelProp: 'description',
	            										placeholder: '',
	            										options: columns,
	            										required: true
	            									},
	            									data: {
	            										row: true,
	            										informed: true,
	            										colClass: ' col-xs-12',
	            										labelClass: 'label-strong',
	            										clear: function($event,model,key, $select) {
	            											$event.stopPropagation();
	            											model[key] = undefined;
	            											if($select) {
	            												$select.selected = undefined;
	            												$select.search = undefined;
	            											}
	            										}
	            									}
	            								});
	            								if(!modal.annexaFormly.model.modal_body.relatedCustomFields){
	            									modal.annexaFormly.model.modal_body.relatedCustomFields = {};
	            								}
	            								modal.annexaFormly.model.modal_body.relatedCustomFields[cfAux.templateTag] = this.annexaFormly.model.modal_body.relatedQueryColumn;
	            							}
	            						} 
	            					}
	            					this.close();
	            				}
	            				var modalRelated = angular.copy(CommonAdminModals.customFieldsRelated);
	            				modalRelated.alerts = [];
	            				modalRelated.annexaFormly.model = {modal_body: {}};
	    	            		var cfRt = $linq(modalRelated.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'relatedCustomField'");
	    	            		if(cfRt && cfRt.templateOptions){
	    	            			cfRt.templateOptions.options = angular.copy($linq(vm.customFields).except(addedCF, "(x, y) => y.customField && y.customField.id == x").select("x => x.customField").toArray());
	    	            		}
	    	            		var cfRt = $linq(modalRelated.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'relatedQueryColumn'");
	    	            		if(cfRt && cfRt.templateOptions){
	    	            			cfRt.templateOptions.options = angular.copy(columns);
	    	            		}
	    						AnnexaFormlyFactory.showModal('modalCustomFieldParentRelated',modalRelated,saveCustomFieldRelatedFunction,false);
	            			};
	            		}
	            		var cft = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'relatedCustomFields'");
	            		var paraAux = ((modal.annexaFormly.model.modal_body.relatedCustomFields)?angular.copy(modal.annexaFormly.model.modal_body.relatedCustomFields):[]);
	            		modal.annexaFormly.model.modal_body.relatedCustomFields = {};
	            		if(cft && modal.annexaFormly.model.modal_body.customField && modal.annexaFormly.model.modal_body.customField.fromQuery && modal.annexaFormly.model.modal_body.customField.query && modal.annexaFormly.model.modal_body.customField.query.id && paraAux && paraAux.length > 0 ){
							_.forEach(paraAux, function(param){
								if(param && param.customFieldTemplateTag){
									var cfAux = $linq(vm.customFields).select("x => x.customField").firstOrDefault(undefined, "x => x.templateTag == '"+param.customFieldTemplateTag+"'");
            						if(cfAux && cfAux.templateTag){
            							if(cft && cft.fieldGroup){
            								addedCF.push(cfAux.id);
            								cft.fieldGroup.push({
            									key: cfAux.templateTag,
            									type: 'annexaSelectRow',
            									className: 'col-sm-4 m-b-xs',
            									templateOptions: {
            										optionsAttr: 'bs-options',
            										ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
            										label: cfAux[vm.languageColumn],
            										valueProp: 'id',
            										labelProp: 'description',
            										placeholder: '',
            										options: columns,
            										required: true
            									},
            									data: {
            										row: true,
            										informed: true,
            										colClass: ' col-xs-12',
            										labelClass: 'label-strong',
            										clear: function($event,model,key, $select) {
            											$event.stopPropagation();
            											model[key] = undefined;
            											if($select) {
            												$select.selected = undefined;
            												$select.search = undefined;
            											}
            										}
            									}
            								});
            							}
            						}
									var cfval = ((paraAux)?$linq(paraAux).firstOrDefault(undefined, "x => x.customFieldTemplateTag == '"+param.customFieldTemplateTag+"'"):undefined);
									modal.annexaFormly.model.modal_body.relatedCustomFields[param.customFieldTemplateTag] = ((cfval)?cfval.customFieldValue:undefined);
								}
							});
	            		}
	            		var cft = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'defaultValueReloadButton'");
	            		if(cft && cft.templateOptions){
	            			cft.templateOptions.objectParentId = vm.parentId;
	            			cft.templateOptions.objectParentType = vm.type;
	            			cft.templateOptions.inConfig = true;
	            		}
	            		var cft = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'configGroup'");
	            		if(cft && cft.data){
	            			var calculateGroups = function(groups, cfGroups){
	            				if(cfGroups && cfGroups.length > 0){
	            					var groupsCreated = [];
	            					if(groups && groups.length > 0){
	            						_.forEach(cfGroups, function(val){
	            							var existCF = false;
	            							_.forEach(groups, function(group){
	            								if(!existCF && group.relatedCustomField && group.relatedCustomField.templateTag && val.relatedCustomField && val.relatedCustomField.templateTag && group.relatedCustomField.templateTag === val.relatedCustomField.templateTag && val.viewOrder && group.viewOrder && val.viewOrder === group.viewOrder){
	            									group.groups = calculateGroups(group.groups, ((val.relatedCustomField && val.relatedCustomField.groups)?val.relatedCustomField.groups:[]));
	            									groupsCreated.push(group);
		            								existCF = true;
	            								}
	            							});
	            							if(!existCF){
			        	                		groupsCreated.push({
			        	                			relatedCustomField: val.relatedCustomField,
			        	                            required: false,
			        	                            requiredForEndDossier: false,
			        	                            hiddenField: false,
			        	                            noEditable: false,
			        	                            conditional: false,
			        	                            spel:undefined,
			        	                            requiredString: 'OPTIONAL',
			        	                            value: '',
			        	                            descriptionLanguage1: val.relatedCustomField.descriptionLanguage1,
			        	                            descriptionLanguage2: val.relatedCustomField.descriptionLanguage2,
			        	                            descriptionLanguage3: val.relatedCustomField.descriptionLanguage3,
			        	                            viewOrder: val.viewOrder,
			        	                            groups: calculateGroups([], ((val.relatedCustomField && val.relatedCustomField.groups)?val.relatedCustomField.groups:[]))
			        	                        });
	            							}
		        	                	});
	            					}else{
	            						_.forEach(cfGroups, function(val){
		        	                		groupsCreated.push({
		        	                			relatedCustomField: val.relatedCustomField,
		        	                            required: false,
		        	                            requiredForEndDossier: false,
		        	                            hiddenField: false,
		        	                            noEditable: false,
		        	                            conditional: false,
		        	                            spel:undefined,
		        	                            requiredString: 'OPTIONAL',
		        	                            value: '',
		        	                            descriptionLanguage1: val.relatedCustomField.descriptionLanguage1,
		        	                            descriptionLanguage2: val.relatedCustomField.descriptionLanguage2,
		        	                            descriptionLanguage3: val.relatedCustomField.descriptionLanguage3,
		        	                            viewOrder: val.viewOrder,
		        	                            groups: calculateGroups([], ((val.relatedCustomField && val.relatedCustomField.groups)?val.relatedCustomField.groups:[]))
		        	                        });
		        	                	});
	            					}	
	            					return groupsCreated;
	            				}else{
	            					return [];
	            				}
	                        }
	            			
	            			cft.data.groups = modal.annexaFormly.model.modal_body.customField.groups;
	            			cft.data.objectGroups = calculateGroups(modal.annexaFormly.model.modal_body.groups, cft.data.groups);
	            			cft.data.type = vm.type;
	            			cft.data.objectParent = cf.id;
	            			cft.data.parent = vm.parentId;
	            			cft.data.showCustomFieldType = vm.showCustomFieldType;
	            			cft.data.procedureCustomFields = vm.customFields;
	            		}
	            		AnnexaFormlyFactory.showModal('modalCustomFieldParent',modal,saveParentCustomFieldFunction,false);
	            	}).catch(function(error){
	            	})
            	}
            }
            
            vm.addCustomField = function(newCustomField) {
                if(newCustomField.id != -1) {
                    addCF(newCustomField);
                    var indexAllcustomFields = $linq(vm.allCustomFields).indexOf("x => x.id == " + newCustomField.id);
                    if(indexAllcustomFields != -1) {
                        vm.allCustomFields.splice(indexAllcustomFields, 1);
                    }
                } else {
                	CustomFieldFactory.customFieldModal(undefined, addCF);
                }
                vm.addedCustomField = undefined;
            }

            vm.removeCustomFieldFromObject = function(index) {
                var customFieldToRemove = {};
                if(index != undefined && index > -1 && vm.customFields){
                    customFieldToRemove = vm.customFields[index];
                }
                if(customFieldToRemove && !customFieldToRemove.disabled){
                	if(vm.updateOnChange) {
                		switch (vm.type) {
                		case 'DOCUMENT_TYPE':
                			RestService.delete('./api/doc/document_type/custom_field/' + vm.customFields[index].id)
                			.then(function (data) {
                				vm.customFields.splice(index, 1);
                				vm.addRemovedCustomFieldToAllCustomFields(customFieldToRemove);
                			}).catch(function (error) {
                				console.log(error);
                			});
                			break;
                		case 'TASK_TYPE':
                			RestService.delete('./api/task_type/custom_field/' + vm.customFields[index].id)
                			.then(function (data) {
                				vm.customFields.splice(index, 1);
                				vm.addRemovedCustomFieldToAllCustomFields(customFieldToRemove);
                				$rootScope.$broadcast('AnnexaTaskTypeCustomFieldDeleted', { item: customFieldToRemove });
                			}).catch(function (error) {
                				console.log(error);
                			});
                			break;
                		case 'PROCEDURE':
                			var customField = vm.customFields[index];
                			customField.deleted = true;
                			CommonAdminService.insertOrUpdateProcedureCustomField(customField)
                			.then(function (data) {
                				vm.customFields.splice(index, 1);
                				vm.addRemovedCustomFieldToAllCustomFields(customFieldToRemove);
                                $rootScope.$broadcast('AnnexaProcedureCustomFieldUpdated', { item: customFieldToRemove });
                			}).catch(function (error) {
                				customField.deleted = false;
                				console.error(error);
                			});
                			break;
                		case 'TRANSACTION_TYPE':
                			var customField = vm.customFields[index];
                			customField.deleted = true;
                			CommonAdminService.insertOrUpdateTransactionTypeCustomField(customField)
                			.then(function (data) {
                				vm.customFields.splice(index, 1);
                				vm.addRemovedCustomFieldToAllCustomFields(customFieldToRemove);
                			}).catch(function (error) {
                				customField.deleted = false;
                				console.error(error);
                			});
                			break;
                		}
                	} else {
                		vm.customFields.splice(index, 1);
                		vm.addRemovedCustomFieldToAllCustomFields(customFieldToRemove);
                		if(vm.type == 'PROCEDURE') {
                			customFieldToRemove.deleted = true;
                			$rootScope.$broadcast('AnnexaProcedureCustomFieldUpdated', { item: customFieldToRemove });
                		}
                	}
                }
            }

            vm.addRemovedCustomFieldToAllCustomFields = function(customFieldToRemove){
                if(customFieldToRemove && customFieldToRemove.customField){
                    vm.allCustomFields.push(customFieldToRemove.customField);
                    vm.allCustomFields = $linq(vm.allCustomFields).orderBy("x => x." + vm.languageColumn, HelperService.caseInsensitiveOrNullComparer).toArray();
                    var indexNewcustomField = $linq(vm.allCustomFields).indexOf("x => x.id == -1");
                    if(indexNewcustomField != -1) {
                    	var newCF = vm.allCustomFields[indexNewcustomField];
                        vm.allCustomFields.splice(indexNewcustomField, 1);
                        vm.allCustomFields.unshift(newCF);
                    }
                }
            }


            vm.sort = function(item, partFrom, partTo, indexFrom, indexTo){
                if(partTo){
                    _.forEach(partTo, function(value, key){
                        value.viewOrder = key + 1;
                        vm.update(value);
                    });
                }
            }

            vm.update = function(customField, onSave) {
                customField.required = customField.requiredString == 'REQUIRED';
                customField.requiredForEndDossier = customField.requiredString == 'REQUIRED_TO_END';
                customField.hiddenField = customField.requiredString == 'HIDDEN_FIELD';
                customField.noEditable = customField.requiredString == 'NO_EDITABLE';
				if(vm.type == 'PROCEDURE' && vm.showCustomFieldType){
					if(customField.customFieldType !== 'DOSSIER_TRANSACTIONS_TYPE' && customField.customFieldType !== 'DOSSIER_AND_DOSSIER_TRANSACTIONS_TYPE' && customField.customFieldType !== 'DOSSIER_TRANSACTIONS_TYPE_WITH_COPY' && customField.customFieldType !== 'DOSSIER_AND_DOSSIER_TRANSACTIONS_TYPE_WITH_COPY'){
						customField.transactionTypeLanguage = '';
					}
				}
                if(vm.updateOnChange) {
                    switch (vm.type) {
                        case 'DOCUMENT_TYPE':
                            if(customField.id) {
                                RestService.update('./api/doc/document_type/custom_field/' + customField.id, customField)
                                    .then(function (data) {
                                        if(onSave){
                                        	onSave(data);
                                        }
                                    }).catch(function (error) {
                                        console.error(error);
                                });
                            } else {
                                RestService.insert('./api/doc/document_type/custom_field', customField)
                                    .then(function (data) {
                                        vm.customFields[vm.customFields.length - 1].id = data.id;
                                        vm.customFields[vm.customFields.length - 1].customField.id = data.customField.id;
                                    }).catch(function (error) {
                                        console.error(error);
                                });
                            }
                            break;
                        case 'TASK_TYPE':
                            if(customField.id) {
                                RestService.update('./api/task_type/custom_field/' + customField.id, customField)
                                    .then(function (data) {
                                    	$rootScope.$broadcast('AnnexaTaskTypeCustomFieldUpdated', { item: customField });
                                    	if(onSave){
                                        	onSave(data);
                                        }
                                    }).catch(function (error) {
                                        console.error(error);
                                });
                            } else {
                                RestService.insert('./api/task_type/custom_field', customField)
                                    .then(function (data) {
                                        vm.customFields[vm.customFields.length - 1].id = data.id;
                                        vm.customFields[vm.customFields.length - 1].customField.id = data.customField.id;
                                        vm.customFields[vm.customFields.length - 1].taskType = {id:vm.parentId};
                                        data.taskType = {id:vm.parentId}
                                        $rootScope.$broadcast('AnnexaTaskTypeCustomFieldUpdated', { item: data });
                                    }).catch(function (error) {
                                        console.error(error);
                                });
                            }
                            break;
                        case 'PROCEDURE':
                            CommonAdminService.insertOrUpdateProcedureCustomField(customField)
                                .then(function (data) {
                                    if(customField && !customField.id){
                                        customField.id = data.id;
                                    }
                                    if(onSave){
                                    	onSave(data);
                                    }
                                    $rootScope.$broadcast('AnnexaProcedureCustomFieldUpdated', { item: data });
                                }).catch(function (error) {
                                console.error(error);
                            });
                            break;
						case 'TRANSACTION_TYPE':
                            CommonAdminService.insertOrUpdateTransactionTypeCustomField(customField)
                                .then(function (data) {
                                    if(customField && !customField.id){
                                        customField.id = data.id;
                                    }
                                    if(onSave){
                                    	onSave(data);
                                    }
                                }).catch(function (error) {
                                console.error(error);
                            });
                            break;
                    }
                }else{
                	if(onSave){
                    	onSave();
                    }
                }
            }

            this.$onInit = function() {
                if(!vm.customFields) {
                    vm.customFields = [];
                }
                vm.customFields = $linq(vm.customFields).orderBy("x => x.viewOrder").toArray();
                vm.allCustomFields = [
                    { id: '-1', value: '', language1: $filter('translate')('global.literals.new'), language2: $filter('translate')('global.literals.new'), language3: $filter('translate')('global.literals.new')}
                ];

				if(GlobalDataFactory.transactiontypes){
					vm.transactionTypes = angular.copy(GlobalDataFactory.transactiontypes);
				}
                 var responseCF = GlobalDataFactory.customFields;
                 _.forEach($linq(responseCF).orderBy("x => x." + vm.languageColumn, HelperService.caseInsensitiveOrNullComparer).toArray(), function(item) {
                     if(vm.type == "DOCUMENT_TYPE" && item.backendType != "ADDRESS"){
                    	 vm.allCustomFields.push(item);
                     } else if(vm.type != "DOCUMENT_TYPE") {
                    	 vm.allCustomFields.push(item);
                     }
                 });

                 if(vm.allCustomFields && vm.customFields){
                     vm.allCustomFields = $linq(vm.allCustomFields).except(vm.customFields, function(x,y){
                         if(x.customField && x.customField.id == y.id){
                             return true;
                         }
                         return false;
                     }).toArray();
                 }

                if(vm.type == 'PROCEDURE') {
                    vm.customFieldRequiredList.push({ id: 'REQUIRED_TO_END', description: 'global.literals.REQUIRED_TO_END' });
                    vm.customFieldRequiredList.push({ id: 'HIDDEN_FIELD', description: 'global.literals.HIDDEN_FIELD' });
					if(vm.showType && vm.showType === "true"){
						vm.showCustomFieldType = true;
						_.forEach(vm.customFields, function(customField){
							customField.transactionTypesAux = [];
							if(customField.transactionTypes){
								var ttsLiteral = '';
								_.forEach(customField.transactionTypes, function(tt){
									if(tt.transactionType && tt.transactionType.id && vm.transactionTypes){
										var ttAux = $linq(vm.transactionTypes).firstOrDefault(undefined, "x => x.id ==" + tt.transactionType.id);
										if(ttAux){
											ttsLiteral = ((ttsLiteral && ttsLiteral !== '')?', '+ttAux[vm.languageColumn]:ttAux[vm.languageColumn]);
										}
									}
								});
								customField.transactionTypeLanguage = ttsLiteral;
							}
						});
					};
                }
            }
        }],
        bindings: {
            customFields: '=',
            type: '@',
            updateOnChange: '=',
            parentId: '=',
            parentProperty: '@',
			showType: '@?'
        }
    })